<div class="full-page w-100 px-0">
  <!--Left Side-->
  <div class="left-side">
    <img
      class="shared-login-banner d-md-block d-none"
      draggable="false"
      src="assets/images/login-registration/login-banner-v2.jpg"
    />
  </div>
  <div class="bg-primary d-flex w-100 d-md-none m-auto mobile-header"></div>
  <!--Right Side-->
  <div class="right-side">
    <div class="activate-container">
      <h2 class="text-primary text-uppercase mb-2 mb-lg-0">{{ 'activate.account.page.title' | translate }}</h2>
      <div class="text-black panel-text mlb-text font-size-14">
        {{ 'activate.account.page.description' | translate }}
      </div>
      <form class="activate-form" [formGroup]="activateForm" (ngSubmit)="submit()">
        <div class="form-floating mb-3">
          <input
            [type]="isPasswordVisible ? 'text' : 'password'"
            formControlName="Password"
            class="form-control"
            id="floatingInput"
            placeholder="Insert new password here"
          />
          <label for="floatingInput">{{ 'activate.account.form.newpassword.label' | translate }}</label>
          <div
            *ngIf="activateForm.get('Password')?.hasError('pattern') && activateForm.get('Password')?.touched"
            class="alert-danger"
          >
            {{ 'activate.account.invalid.password' | translate }}
          </div>
          <div
            *ngIf="activateForm.get('Password')?.hasError('required') && activateForm.get('Password')?.touched"
            class="alert-danger"
          >
            {{ 'activate.account.password.required' | translate }}
          </div>
          <i class="material-icons text-info" (click)="isPasswordVisible = !isPasswordVisible">{{
            isPasswordVisible ? 'visibility' : 'visibility_off'
          }}</i>
        </div>
        <div class="form-floating">
          <input
            [type]="isConfirmPasswordVisible ? 'text' : 'password'"
            formControlName="ConfirmPassword"
            class="form-control"
            id="floatingPassword"
            placeholder="Insert password here"
          />
          <label for="floatingPassword">{{ 'activate.account.form.confirmpassword.label' | translate }}</label>
          <div
            *ngIf="activateForm.get('ConfirmPassword')?.hasError('required') && activateForm.get('ConfirmPassword')?.touched"
            class="alert-danger"
          >
            {{ 'activate.account.confirm.password.required' | translate }}
          </div>
          <i class="material-icons text-info" (click)="isConfirmPasswordVisible = !isConfirmPasswordVisible">{{
            isConfirmPasswordVisible ? 'visibility' : 'visibility_off'
          }}</i>
        </div>
        <div *ngIf="activateForm?.hasError('passwordMismatch') && activateForm?.touched" class="alert-danger">
          {{ 'activate.account.password.mismatch' | translate }}
        </div>
        <div class="action-buttons mt-4">
          <button type="submit" class="btn btn-primary col-12">
            {{ 'activate.account.form.submit' | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
