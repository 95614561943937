<ng-container *ngIf="worksheetItem.WorksheetType == worksheetType.Download">
  <!-- pdf -->
  <div class="card border-0 my-3 rounded">
    <div class="img-container">
      <img
        [src]="worksheetItem.Image | contentImage : '6' : 'marketplace'"
        [alt]="worksheetItem.Title"
        [useDefault]="true"
        [loyaltyType]="'marketplace'"
        draggable="false"
      />
      <div class="description-container">
        <h2 class="my-2 fw-bold">{{ worksheetItem.Description }}</h2>
      </div>
    </div>
    <div class="action-buttons bg-primary">
      <div
        target="_blank"
        (click)="getDownload(worksheetId, worksheetItem.Id)"
        class="btn text-white border-0 fw-bold font-size-14"
      >
        {{ 'worksheet.download' | translate | uppercase }}
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="worksheetItem.WorksheetType == worksheetType.Gallery">
  <!-- image -->
  <div class="my-3 rounded">
    <div class="text-center">
      <img
        [src]="worksheetItem.Image | contentImage : '1' : 'marketplace'"
        [alt]="worksheetItem.Title"
        [useDefault]="true"
        draggable="false"
        class="worksheet-image rounded"
      />
    </div>
  </div>
</ng-container>

<ng-container *ngIf="worksheetItem.WorksheetType == worksheetType.ProviderVideo">
  <!-- video -->
  <div class="card border-0 shadow my-3 rounded">
    <div [ngSwitch]="worksheetItem.ProviderId">
      <ng-container *ngSwitchCase="providerName.Facebook">
        <fgb-facebook-player [streamKey]="worksheetItem.ProviderKey"></fgb-facebook-player>
      </ng-container>
      <ng-container *ngSwitchCase="providerName.YouTube">
        <fgb-youtube-player [streamKey]="worksheetItem.ProviderKey"></fgb-youtube-player>
      </ng-container>
      <ng-container *ngSwitchCase="providerName.StreamingVideoProvider">
        <fgb-svp-player [streamKey]="worksheetItem.ProviderKey"></fgb-svp-player>
      </ng-container>
      <ng-container *ngSwitchCase="providerName.Twitch">
        <fgb-twitch-player [streamKey]="worksheetItem.ProviderKey"></fgb-twitch-player>
      </ng-container>
      <ng-container *ngSwitchCase="providerName.Viemo">
        <fgb-vimeo-player [streamKey]="worksheetItem.ProviderKey"></fgb-vimeo-player>
      </ng-container>
      <ng-container *ngSwitchCase="providerName.Boxcast">
        <fgb-boxcast-player [streamKey]="worksheetItem.ProviderKey"></fgb-boxcast-player>
      </ng-container>
      <ng-container *ngSwitchCase="providerName.Streamable">
        <fgb-streamable-player [streamKey]="worksheetItem.ProviderKey"></fgb-streamable-player>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="worksheetItem.WorksheetType == worksheetType.Link">
  <!-- link -->
  <div class="card border-0 shadow my-3 rounded p-2">
    <div>
      <img
        style="height: 55px; max-width: 200px"
        [src]="worksheetItem.Image | contentImage : '6' : 'marketplace'"
        [alt]="worksheetItem.Title"
        [useDefault]="true"
        [loyaltyType]="'marketplace'"
        draggable="false"
      />

      <div class="my-2 fw-bold">{{ worksheetItem.Description }}</div>

      <a
        target="_blank"
        [href]="worksheetItem.LinkUrl"
        class="btn btn-primary bg-primary text-white border-0 fw-bold font-size-14"
        >{{ 'worksheet.download' | translate | uppercase }}</a
      >
    </div>
  </div>
</ng-container>
