<div class="container-fluid">
  <div class="row">
    <div class="col-lg-6 d-none d-lg-flex login-banner"></div>
    <div class="user-login-panel col-lg-6 col-12 text-left p-0">
      <div class="d-flex justify-content-center d-lg-none bg-primary align-items-center logo-container">
        <img alt="" class="register-logo" src="assets/images/logo-v2.png" draggable="false" />
      </div>
      <div class="col-12 register-panel">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
