<div class="banner justify-content-center d-none d-lg-flex" role="banner">
  <h1 class="text-white">{{ 'benefits.page.title' | translate }}</h1>
</div>

<div class="container" *ifScreenSize="screenType.Mobile">
  <div class="mt-4">
    <fgb-barcode></fgb-barcode>
  </div>
</div>

<fgb-account-nav></fgb-account-nav>

<div class="container">
  <div
    class="font-size-14 border rounded text-center p-3 benefits-description"
    [innerHTML]="'benefits.page.description' | translate | markdownTranslate"
  ></div>

  <fgb-worksheet-list [isList]="false"></fgb-worksheet-list>
</div>
