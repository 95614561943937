<div
  ngbDropdown
  class="notification-button pt-lg-1"
  [class.open]="isOpen"
  (openChange)="toggleNotificationCenter($event)"
  #notificationDropdown="ngbDropdown"
>
  <button
    class="dropdown-toggle notification-btn btn border-0 px-lg-4 border-right"
    id="notificationDropdown"
    attr.aria-label="{{ 'nav.notification.screenreader' | translate }}"
    tabindex="-1"
    ngbDropdownToggle
  >
    <span class="icon notification-bell text-white material-icons material-mobile-nav" aria-hidden="true">notifications</span>
    <ng-container *ngIf="unseenNotificationCount$ | async">
      <span class="notification-badge">
        <div class="count-notification">{{ unseenNotificationCount$ | async }}</div>
      </span>
    </ng-container>
  </button>

  <div ngbDropdownMenu aria-labelledby="notificationDropdown" class="notification-dropdown shadow dropdown-menu border-0 p-0">
    <div class="notification-header d-flex ps-3">
      <div class="text-black fw-bold font-size-14 text-uppercase">
        {{ 'navbar.notifications.title' | translate : { fgbdefault: 'Notifications' } }}
      </div>
      <i class="material-icons close-btn text-info" (click)="notificationDropdown.close()"></i>
    </div>
    <fgbcl-notification-hub (goToNotification)="closeDropDown(notificationDropdown)"></fgbcl-notification-hub>
  </div>
  <div class="notification-mobile-backdrop"></div>
</div>
