<div role="contentinfo" class="footer bg-primary text-white text-center">
  <div class="footer-quicklinks bg-black w-100">
    <a href="{{ 'footer.privacy.link.url' | translate }}">
      <div class="privacy-link text-white font-size-14 font-heading">{{ 'footer.privacy.link' | translate }}</div>
    </a>
  </div>
  <div class="footer-text d-flex align-items-center justify-content-center w-100">
    <div class="footer-content mt-lg-3 mt-2">
      <img alt="" class="footer-logo mb-3" src="assets/images/footer-logos/footer-white-v2.png" draggable="false" />
      <p
        class="text-white font-size-12 text-center footer-text-rights"
        [innerHTML]="'nav.club.rights' | translate | markdownTranslate"
      ></p>
    </div>
  </div>
</div>
