<div class="fantasy-panel rounded overflow-hidden">
  <div class="row mx-0">
    <div class="col-lg-7 px-0 bg-info">
      <div class="game-summary">
        <div class="d-none d-lg-block">
          <div class="text-center line-height-18 text-white">
            <p class="pt-2 mb-2 font-size-12">{{ 'pick.player.next.game.title' | translate }}</p>
            <p class="font-size-14 mb-0">{{ 'pick.player.next.game.description' | translate }}</p>
          </div>
          <div class="row team-vs-team d-flex justify-content-center align-items-center mx-0">
            <div class="team home col-4">
              <img
                *ngIf="game.HomeTeamLogo"
                class="d-flex ms-auto me-auto mt-3 mt-lg-0"
                src="{{ game.HomeTeamLogo | contentImage: '7' }}"
              />
            </div>
            <div class="versus col-4 text-center">
              <div class="font-page-heading font-size-20 text-white">{{ 'games.pickaplayer.vs' | translate }}</div>
            </div>
            <div class="team away col-4">
              <img
                *ngIf="game.AwayTeamLogo"
                class="d-flex ms-auto me-auto mt-3 mt-lg-0"
                src="{{ game.AwayTeamLogo | contentImage: '7' }}"
              />
            </div>
          </div>
          <div class="line-height-18 font-size-12 text-center mt-4 pt-2">
            <div class="text-white">{{ gameDate | date: 'dd MMM' }}, {{ gameTime }}</div>
          </div>
        </div>
        <div class="d-block d-lg-none block-coming-soon">
          <div class="row p-3 py-2">
            <div class="col-4 line-height-18 text-white">
              <p class="pt-2 mb-1 font-size-12">{{ 'pick.player.next.game.title' | translate }}</p>
              <p class="font-size-14 mb-0">{{ 'pick.player.next.game.description' | translate }}</p>
            </div>
            <div class="col-8 pt-1">
              <div class="row d-flex justify-content-center align-items-center mx-0">
                <div class="team home col-4">
                  <img *ngIf="game.HomeTeamLogo" class="d-flex mx-auto" src="{{ game.HomeTeamLogo | contentImage : '6' }}" />
                </div>
                <div class="versus col-4 text-center">
                  <div class="font-page-heading font-size-20 text-white">{{ 'games.pickaplayer.vs' | translate }}</div>
                </div>
                <div class="team away col-4">
                  <img *ngIf="game.AwayTeamLogo" class="d-flex mx-auto" src="{{ game.AwayTeamLogo | contentImage : '6' }}" />
                </div>
              </div>
              <div class="line-height-18 font-size-12 text-center">
                <div class="text-white">{{ gameDate | date: 'dd MMM' }}, {{ gameTime }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-5 py-2 px-3 right-col">
      <div class="d-none d-lg-block">
        <div class="player-select text-center">
          <div class="player-image mx-auto rounded-circle border overflow-hidden">
          </div>
          <div class="question-text fw-bold font-size-14">
            {{ 'pick.player.next.game.no.player' | translate }}
          </div>
          <button class="btn btn-info btn-select w-100 mb-2 bg-primary border-0 font-heading" [disabled]="true">
            {{ 'pick.player.next.game.select.button' | translate }}
          </button>
  
          <fgb-fantasy-player-select-confirm [isGamePending]="true" [game]="game"> </fgb-fantasy-player-select-confirm>
        </div>
      </div>
      <div class="d-block d-lg-none">
        <div class="player-select text-center">
          <div class="row">
            <div class="col-4">
              <div class="player-image mx-auto rounded-circle border overflow-hidden">
              </div>
            </div>
            <div class="col-8">
              <div class="ms-4 ps-2">
                <div class="question-text font-heading font-size-14 m-3">
                  {{ 'pick.player.next.game.no.player' | translate }}
                </div>
                <button class="btn btn-info btn-select mb-2 bg-primary border-0 font-heading" [disabled]="true">
                  {{ 'pick.player.next.game.select.button' | translate }}
                </button>
                <fgb-fantasy-player-select-confirm [isGamePending]="true" [game]="game"> </fgb-fantasy-player-select-confirm>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
