import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { FantasyLeaderboard } from '@fgb/core';
import { Observable, Subscription } from 'rxjs';
import * as _moment from 'moment';
import { OwlCarouselSettings } from 'src/app/shared/utilities/owl-carousel-settings';
import { FantasyService, MonthlyLeaderboard } from 'src/app/modules/fantasy/fantasy-leaderboard.service';
@Component({
  selector: 'fgb-fantasy-leaderboard-page',
  templateUrl: './fantasy-leaderboard-page.component.html',
  styleUrls: ['./fantasy-leaderboard-page.component.scss'],
})
export class FantasyLeaderboardPageComponent implements OnInit, OnDestroy {
  @Input() backButtonLink: string = '/games/pickaplayer';

  yearlyLeaderboard$: Observable<FantasyLeaderboard[]>;
  monthlyLeaderboard$: Observable<MonthlyLeaderboard[]>;
  fantasySub: Subscription;

  showMonthly: boolean = true;
  year: number = new Date().getFullYear();

  customOptions: OwlOptions = {
    items: 1,
    margin: OwlCarouselSettings.Margin,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    navSpeed: 700,
    nav: true,
    dots: false,
    startPosition: this.fantasyService.monthSlideIndex,
    navText: ['<i class="material-icons fw-bold">chevron_left</i>', '<i class="material-icons fw-bold">chevron_right</i>'],
    stagePadding: 0,
  };

  constructor(private fantasyService: FantasyService) {}

  ngOnInit() {
    this.yearlyLeaderboard$ = this.fantasyService.getYearlyLeaderboard(3, true);

    this.monthlyLeaderboard$ = this.fantasyService.getMonthlyLeaderboard(3, true);
  }

  ngOnDestroy() {
    if (this.fantasySub) {
      this.fantasySub.unsubscribe();
    }
  }
}
