<div class="banner d-flex justify-content-center" role="banner">
  <h1 class="text-white">{{ 'pick.player.leaderboard.page.title' | translate }}</h1>
</div>
<div class="container">
  <div>
    <a [routerLink]="['/games/pickaplayer']" class="btn d-flex align-items-center fw-bold px-0 mt-2">
      <span class="material-icons text-error"> chevron_left </span>
      <span class="fw-bold font-size-14 text-error">{{ 'pick.player.back.button' | translate }}</span>
    </a>
  </div>
  <div class="card mt-3 rounded border">
    <div class="nav d-flex align-items-center justify-content-center">
      <a class="nav-item w-25 text-center" [ngClass]="{ selected: showMonthly }" (click)="showMonthly = true">
        <label class="font-size-14 fw-bold text-center">{{ 'pick.player.leaderboard.monthly' | translate }}</label>
      </a>
      <a class="nav-item w-25 text-center" [ngClass]="{ selected: !showMonthly }" (click)="showMonthly = false">
        <label class="font-size-14 fw-bold text-center">{{ 'pick.player.leaderboard.yearly' | translate }}</label>
      </a>
    </div>
    <div class="leaderboard-nav" *ngIf="showMonthly; else yearly">
      <owl-carousel-o [options]="customOptions" #monthCarousel>
        <ng-template carouselSlide *ngFor="let item of monthlyLeaderboard$ | async; let i = index" [id]="i.toString()">
          <div class="py-2 bg-disabled leaderboard-date-toggle">
            <h2 class="text-center fw-bold font-size-14 m-0">{{ item.monthDisplayText }}</h2>
          </div>
          <fgb-fantasy-leaderboard [fantasyEntries]="item.entries" [topNumber]="10"></fgb-fantasy-leaderboard>
        </ng-template>
      </owl-carousel-o>
    </div>
    <ng-template #yearly>
      <div class="py-2 yearly-header bg-disabled">
        <h2 class="text-center fw-bold font-size-14 m-0">{{ 'fantasy.leaderboard.year' | translate }}</h2>
      </div>
      <fgb-fantasy-leaderboard [fantasyEntries]="yearlyLeaderboard$ | async" [topNumber]="10"></fgb-fantasy-leaderboard>
    </ng-template>
  </div>
</div>
