import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { LoggingService } from '@fgb/core';
import { AccountActivationService } from './account-activation.service';
import { catchError, map, of } from 'rxjs';

export const accountActivationGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const loggingService = inject(LoggingService);
  const accountActivationService = inject(AccountActivationService);

  const key = route.queryParamMap.get('key');

  if( !key ){
    loggingService.error('No activation key provided. Redirecting to login page...');
    return router.createUrlTree(['/login']);
  }

  return accountActivationService.validateActivationCode(key).pipe(
    map(() => {
      loggingService.info('Activation key is valid. Proceeding to account activation...');
      return true;
    }),
    catchError(() => {
      loggingService.error('Invalid activation key provided. Redirecting to login page...');
      return of(router.createUrlTree(['/login']));
    })
  );
};
