import { Component, OnInit } from '@angular/core';
import { config, AnnouncementService, MemberQuery, MemberDetails, MemberCardQuery, MemberCard } from '@fgb/core';
import { Observable } from 'rxjs';
import { ProductCode } from 'src/app/shared/utilities/product-code';

@Component({
  selector: 'fgb-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit {
  config = config;
  member$: Observable<MemberDetails | undefined>;
  card$: Observable<MemberCard | undefined>;
  productCode = ProductCode;
  constructor(
    private announcementService: AnnouncementService,
    private memberQuery: MemberQuery,
    private memberCardQuery: MemberCardQuery
  ) {}

  ngOnInit() {
    this.member$ = this.memberQuery.selectMemberDetails();
    this.card$ = this.memberCardQuery.selectObservedMemberCard();
    this.announcementService.fetchAnnouncements().toPromise();
  }
}
