<div class="edit-login-details-card card rounded border">
  @if (isEdit) {
  <form [formGroup]="editLoginDetailsForm" (ngSubmit)="submit()">
    <div class="form-floating position-relative mb-3">
      <input
        class="form-control"
        type="text"
        formControlName="EmailAddress"
        (click)="editLoginDetailsForm.get('EmailAddress').markAsDirty()"
      />
      <label class="text-info">{{ 'edit.login.details.email.label' | translate }}</label>
      <div class="error-message text-danger" *ngIf="editLoginDetailsForm.get('EmailAddress').dirty">
        *{{ 'edit.login.details.email.dirty' | translate }}
      </div>
      <div
        class="error-message text-danger"
        *ngIf="editLoginDetailsForm.get('EmailAddress').touched && editLoginDetailsForm.get('EmailAddress').hasError('required')"
      >
        *{{ 'edit.login.details.email.required' | translate }}
      </div>
    </div>
    <div class="mb-3">
      <fgb-input-password formControlName="Password" [label]="'edit.login.details.current.password.label' | translate" />

      <div
        class="error-message text-danger"
        *ngIf="editLoginDetailsForm.get('Password').touched && editLoginDetailsForm.get('Password').hasError('required')"
      >
        {{ 'edit.login.details.password.required' | translate }}
      </div>
    </div>
    <div class="form-floating position-relative">
      <fgb-input-password formControlName="NewPassword" [label]="'edit.login.details.current.newpassword.label' | translate" />
      <div
        class="error-message text-danger"
        *ngIf="editLoginDetailsForm.get('NewPassword').touched && editLoginDetailsForm.get('NewPassword').hasError('required')"
      >
        {{ 'edit.login.details.newpassword.required' | translate }}
      </div>
      <div
        class="error-message text-danger"
        *ngIf="editLoginDetailsForm.get('NewPassword').touched && editLoginDetailsForm.get('NewPassword').hasError('pattern')"
      >
        {{ 'edit.login.details.newpassword.pattern' | translate }}
      </div>
    </div>
    <div class="form-floating position-relative">
      <fgb-input-password
        formControlName="ConfirmPassword"
        [label]="'edit.login.details.current.confirm.password.label' | translate"
      />
      <div
        class="error-message text-danger"
        *ngIf="
          editLoginDetailsForm.get('ConfirmPassword').touched && editLoginDetailsForm.get('ConfirmPassword').hasError('required')
        "
      >
        {{ 'edit.login.details.confirm.password.required' | translate }}
      </div>
    </div>

    <div
      class="error-message text-danger"
      *ngIf="editLoginDetailsForm.touched && editLoginDetailsForm.hasError('passwordMismatch')"
    >
      {{ 'edit.login.details.password.mismatch' | translate }}
    </div>
    <fgbcl-error></fgbcl-error>
    <div class="action-buttons justify-content-end d-flex">
      <button class="btn text-danger cancel-button fw-bold" (click)="cancel()">
        {{ 'edit.login.details.cancel.button' | translate }}
      </button>
      <button class="btn btn-success save-button fw-bold text-white" type="submit">
        {{ 'edit.login.details.save.button' | translate }}
      </button>
    </div>
  </form>
  }@else {
  <div class="form-field">
    <label class="mb-0">{{ 'edit.login.details.email.label' | translate }}</label>
    <div class="d-flex bg-muted p-2 m-0 mt-1 rounded text-field">
      <span class="ms-1 w-75">
        <span>{{ memberDetails()?.EmailAddress1 }}</span>
      </span>
    </div>
  </div>
  <div class="form-field">
    <label class="mb-0">{{ 'edit.login.details.password.label' | translate }}</label>
    <div class="d-flex bg-muted p-2 m-0 mt-1 mb-0 rounded text-field">
      <span class="ms-1 w-75">
        <span>************</span>
      </span>
    </div>
  </div>
  <div class="action-buttons">
    <button
      type="button"
      class="edit-button fw-bold d-block mx-auto border-0 rounded text-white bg-primary"
      (click)="isEdit = true"
    >
      {{ 'edit.login.details.edit.button' | translate }}
    </button>
  </div>
  }
</div>
