<div class="banner justify-content-center d-none d-lg-flex" role="banner">
  <h1 class="text-white">{{ 'home.page.title' | translate }}</h1>
</div>

<div class="container">
  <div class="content-container">
    <div class="row card-container">
      <div class="col-lg-6 col-12">
        <fgb-member-card></fgb-member-card>
      </div>
      <a class="col-lg-6 d-none d-lg-flex" [routerLink]="'/rewards'">
        <div class="rounded challenges-card d-flex flex-column align-items-center justify-content-center">
          <h5 class="text-white">{{ 'home.page.widget.card.title' | translate }}</h5>
          <p class="text-white font-size-14 text-center mb-0">{{ 'home.page.widget.card.message' | translate }}</p>
        </div>
      </a>
    </div>
    <ng-container *notInProductCode="[productCode.Premium]">
      <a class="membership-card border bg-primary d-flex text-white rounded" [routerLink]="['/account']">
        <div class="membership-card-header">
          <h2 class="mt-0 mb-3">{{ 'home.page.membership.card.title' | translate }}</h2>
          <p class="font-size-14">
            {{ 'home.page.membership.card.description' | translate }}
          </p>
        </div>
        <div class="action-button mt-lg-0 mt-3 ms-lg-auto ms-0 align-items-center d-flex">
          <a class="btn btn-white text-primary fw-bold" [routerLink]="['/account']">
            {{ 'membership.card.button' | translate }}
          </a>
        </div>
      </a>
    </ng-container>
    <div class="content-container">
      <!-- Announcements -->
      <fgbcl-announcements></fgbcl-announcements>
    </div>
    <!-- Savings -->
    <ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.savings'">
      <div class="content-container mt-4">
        <div class="savings-card p-3 bg-primary text-white">
          <div class="d-flex flex-column flex-lg-row align-items-center justify-content-center">
            <div class="col-lg-1 ms-2"><span class="icon material-icons savings-icon">savings</span></div>
            <div class="col-lg-8 text-lg-start text-center">
              <div class="card-heading">{{ 'my.savings' | translate }}</div>
              <div class="card-body font-size-14">{{ 'my.savings.desc' | translate }}</div>
            </div>
            <div class="col-lg-3 col-12">
              <button type="button" class="btn card-btn border bg-white text-primary" [routerLink]="['/savings']">
                <span class="ms-1 wallet-button-text font-size-14 font-heading">{{
                  'view.my.savings' | translate : { fgbdefault: 'View My Savings' }
                }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="content-container">
      <!-- Featured Rewards -->
      <fgbcl-featured-rewards></fgbcl-featured-rewards>
    </div>
    <div class="content-container">
      <fgbcl-scorecard-carousel [icon]="'chevron_right'" [showAllView]="true" [phaseThree]="true"></fgbcl-scorecard-carousel>
    </div>
    <div class="content-container">
      <fgb-vouchers></fgb-vouchers>
    </div>
  </div>
</div>
