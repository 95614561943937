<nav class="nav nav-secondary account-details-nav">
  <!--Account-->
  <a
    class="nav-item"
    [routerLink]="['/account']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    attr.aria-label="{{ 'account.nav.account.screenreader' | translate : { fgbdefault: 'Move to Account page' } }}"
  >
    <div class="icon material-icons" aria-hidden="true">person</div>
    <label>{{ 'account.nav.profile' | translate }}</label>
  </a>

  <!--Benefits-->
  <a
    class="nav-item"
    [routerLink]="['/benefits']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    attr.aria-label="{{ 'account.nav.benefits.screenreader' | translate : { fgbdefault: 'Move to benefits page' } }}"
  >
    <div class="icon material-icons" aria-hidden="true">hotel_class</div>
    <label>{{ 'account.nav.benefits' | translate }}</label>
  </a>
</nav>
