<div class="survey-container overflow-hidden rounded-4 predictor row">
  <div class="survey-header mb-1 col-12 col-lg-7 d-flex flex-column">
    <div class="image-container text-white bg-predictor border-0">
      <img src="assets/images/predictor-bg.jpg" alt="assets/images/predictor-bg.jpg"/>
    </div>
    <div class="survey-info d-flex flex-column align-content-between border-top-0 options-box-border flex-grow-1">
      <div class="d-flex justify-content-between mb-3">
        <div class="d-flex align-items-center">
          <div class="gIcon-predictor material-icons text-primary" aria-hidden="true">flaky</div>
        </div>
        <div class="text-end"  *ngIf="!survey.HasBeenAnsweredByUser">
          <span class="material-icons align-middle pe-1 text-primary">schedule</span>
          <fgbcl-countdown
            [endDateTime]="survey.EndDate"
            [requestDateTime]="survey.RequestTime"
            [isUTC]="false"
            class="font-size-16 fw-bold text-primary"
          ></fgbcl-countdown>
        </div>
      </div>
      <div class="font-size-14 mb-lg-5 font-default">
        <div>{{ survey.SurveyQuestion }}</div>
      </div>
      <div class="d-flex align-items-end mt-3 h-100">
        <button
          *ngIf="!survey.HasBeenAnsweredByUser"
          (click)="submitAnswer()"
          class="btn btn-success col-lg-4 col-6 fw-bold mx-auto rounded-4 text-white bg-primary border-0"
          [disabled]="disableSubmitButton"
        >
          Submit
        </button>
        <div class="d-flex flex-row w-100 justify-content-center my-1" *ngIf="survey.HasBeenAnsweredByUser">
          <div class="text-uppercase font-italic font-size-20 px-2 pt-1 font-page-heading">Entered</div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 col-lg-5">
    <ng-container *ngIf="survey.HasBeenAnsweredByUser; else answer">
      <fgb-survey-item-answer-completed-predictor [survey]="survey"></fgb-survey-item-answer-completed-predictor>
    </ng-container>
    <ng-template #answer>
      <div class="h-100 w-100 d-table">
        <div class="d-table-cell align-middle">
          <fgb-survey-item-answer #itemAnswer [survey]="survey" (predictorAnswers)="hasUserAnswered()"></fgb-survey-item-answer>
        </div>
      </div>
    </ng-template>
  </div>
</div>
