<ng-container *ngIf="barcode$ | async as barcode">
  <div class="barcode p-0 mb-3 d-flex flex-wrap flex-column justify-content-around align-items-center rounded border">
    <ng-container *ngIf="card$ | async as card">
      <div class="qrcode-container">
        <ng-container *ngIf="barcode.Barcode; else loading">
          <qrcode
            [qrdata]="barcode.Barcode"
            [margin]="0"
            [width]="90"
            [errorCorrectionLevel]="'M'"
            [elementType]="'svg'"
          ></qrcode>
        </ng-container>
      </div>
      <ng-container *ngIf="member$ | async as member">
        <!-- Not Owner Barcode Body -->
        <div class="w-100 bg-primary barcode-content-container">
          <div class="barcode-content text-white d-flex flex-column align-items-center" *ngIf="card$ | async as card">
            <!--Name and ID-->
            <label class="mb-2 font-size-14 card-name text-uppercase" *ngIf="memberDetails$ | async as memberDetails">
              {{ memberDetails.FirstName }} {{ memberDetails.Surname }}
            </label>
            <div class="d-flex font-size-10">
              <div>{{ card.ProductCode | accountMembership | translate }}</div>
              <div class="px-1">-</div>
              <div>{{ card.MemberId }}</div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-container>

<ng-template #loading>
  <fgbcl-loading></fgbcl-loading>
</ng-template>
