import { Component, Input, OnInit } from '@angular/core';
import { ActionLabels, MarketplaceService } from '@fgb/core';
import { firstValueFrom } from 'rxjs';
import { HideMobileNav } from '@fgb/portal-component-library/src/lib/directives';
@Component({
  selector: 'fgb-marketplace-detail-page',
  templateUrl: './marketplace-detail-page.component.html',
  styleUrls: ['./marketplace-detail-page.component.scss'],
  hostDirectives: [
    HideMobileNav
  ]
})
export class MarketplaceDetailPageComponent implements OnInit {
  @Input() id: number = 0;
  actionLabels = ActionLabels;

  constructor(
    private marketplaceService: MarketplaceService
  ) {}

  ngOnInit() {
    firstValueFrom(this.marketplaceService.fetchMarketplaceItemById(this.id));
  }

}
