<div class="full-page w-100 d-md-flex d-block px-0">
  <!--Left Side-->
  <div class="left-side">
    <img
      class="shared-login-banner d-md-block d-none"
      draggable="false"
      src="assets/images/login-registration/login-banner-v2.jpg"
    />
  </div>
  <div class="bg-primary d-flex w-100 d-md-none m-auto mobile-header"></div>
  <!--Right Side-->
  <div class="right-side-login">
    <img class="maintenance-icon" height="75" draggable="false" src="assets/images/maintenance-icon.svg" />
    <h5
      class="text-primary text-center mt-4 mb-0"
      [innerHTML]="'maintenancepage.title.text' | translate | markdownTranslate"
    ></h5>
    <p class="font-size-14" [innerHTML]="'maintenancepage.message' | translate | markdownTranslate"></p>
  </div>
</div>
