import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { CartService, Cart, CartQuery, PortalProduct } from '@fgb/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'fgb-subscriptions-list-item',
  templateUrl: './subscriptions-list-item.component.html',
  styleUrls: ['./subscriptions-list-item.component.scss'],
})
export class SubscriptionsListItemComponent implements OnInit, OnDestroy {
  @Input() portalProduct: PortalProduct;
  items$: Observable<Cart[]>;
  itemSub: Subscription;
  currentLcid: string | null = '';
  currencyCode: string;

  constructor(
    private modalService: NgbModal,
    private cartService: CartService,
    private cartQuery: CartQuery,
    private router: Router
  ) {
    this.currentLcid = localStorage.getItem('lcid');
  }

  ngOnInit() {
    this.items$ = this.cartQuery.selectAllSubscriptionCartData();
    this.updateCurrency();
  }

  ngOnDestroy() {
    if (this.itemSub) {
      this.itemSub.unsubscribe();
    }
  }

  addSubscriptionProductToCart() {
    this.clearBasket();
    if (this.portalProduct.ProductType === 1) {
      if (!this.checkBasketForMembership()) {
        this.cartService.addPortalProductToCart(this.portalProduct);
      }
    } else {
      if (!this.checkBasketForSubs()) {
        this.cartService.addPortalProductToCart(this.portalProduct);
      }
    }
    this.router.navigate(['/subscriptions/checkout']);
  }

  clearBasket() {
    this.cartQuery.removeItems(this.cartQuery.getMemberPortalProductCart());
  }

  openProductDescription(content: any) {
    this.modalService.open(content, { centered: true });
  }

  // checks if product type 1 portal product exists within basket
  checkBasketForMembership(): boolean {
    let arrCart: Cart[] = [];
    this.itemSub = this.items$.subscribe((x) => {
      arrCart = x;
    });
    for (let item of arrCart) {
      if (item.productData.ProductType === 1) {
        return true;
      }
    }
    return false;
  }
  // checks if product type 3 portal product exists within basket
  checkBasketForSubs(): boolean {
    let arrCart: Cart[] = [];
    this.itemSub = this.items$.subscribe((x) => {
      arrCart = x;
    });
    for (let item of arrCart) {
      if (item.productData.ProductType === 3 && (item.productData as PortalProduct).ProductId === this.portalProduct.ProductId) {
        return true;
      }
    }
    return false;
  }

  updateCurrency() {
    if (this.currentLcid == '2057') {
      this.currencyCode = 'GBP';
    } else {
      this.currencyCode = 'EUR';
    }
  }
}
