<div>
  <div class="row">
    <div class="col-2">
      <div class="player-img-container rounded-circle overflow-hidden mx-auto">
        <img src="{{ fantasyHistoryItem.PlayerImage | contentImage: '6' }}" class="h-100 w-100" />
      </div>
    </div>
    <div class="col-6 d-flex my-1 my-lg-0 align-items-center ps-0">
      <div>
        <div class="font-size-14 fw-bold">
          {{ fantasyHistoryItem.PlayerName }}
        </div>
        <div class="d-inline-flex">
          <div class="font-heading font-size-14">{{ 'games.pickaplayer.vs' | translate }}</div>
          <div class="font-size-12 ms-2">
            {{ fantasyHistoryItem.AwayTeamName }} - {{ gameDate | date: 'MMM dd' }}
          </div>
        </div>
      </div>
    </div>

    <div class="col-4 d-flex align-items-center justify-content-end pe-lg-3 ps-0">
      <div
        class="text-end font-size-14 fw-bold"
        *ngIf="!isPending; else pointsPending"
        [ngClass]="{
          'text-primary': fantasyHistoryItem.PlayerGamePoints > 0,
          'text-info ': fantasyHistoryItem.PlayerGamePoints === 0
        }"
      >
        <span *ngIf="fantasyHistoryItem.PlayerGamePoints > 0">+</span>
        {{ fantasyHistoryItem.PlayerGamePoints / 100 | FortressCurrencyPipe: 'PTS' }}
      </div>
      <ng-template #pointsPending>
        <span class="font-size-14 text-info fw-bold">{{ 'pick.player.history.points.pending' | translate }}</span>
      </ng-template>
    </div>
  </div>
</div>
