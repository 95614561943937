import { Component, OnInit } from '@angular/core';
import { Cart, CartQuery } from '@fgb/core';

@Component({
  selector: 'fgb-portal-product-success-page',
  templateUrl: './portal-product-success-page.component.html',
  styleUrls: ['./portal-product-success-page.component.scss'],
})
export class PortalProductSuccessPageComponent implements OnInit {
  cartItem: Cart;

  constructor(private cart: CartQuery) {}

  ngOnInit() {
    this.cartItem = this.cart.getMemberPortalProductCart()[0];
  }
}
