import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'accountMembership'
})
export class AccountMembershipPipe implements PipeTransform {

  transform(value: string): unknown {
    return this._getMembershipName(value);
  }

  private _getMembershipName(productCode: string): string {

    if( productCode ){
      if( productCode.toLowerCase().includes('free') ){
        return 'account.membership.free';
      }else if ( productCode.toLowerCase().includes('premium') ){
        return 'account.membership.premium';
      }
    }
    
    return '';
  }

}
