import { Component, OnInit, Input } from '@angular/core';

import { FantasyLeaderboard } from '@fgb/core';

@Component({
  selector: 'fgb-fantasy-leaderboard',
  templateUrl: './fantasy-leaderboard.component.html',
  styleUrls: ['./fantasy-leaderboard.component.scss'],
})
export class FantasyLeaderboardComponent implements OnInit {
  @Input() fantasyEntries: FantasyLeaderboard[];
  @Input() topNumber: number;
  constructor() {}

  ngOnInit() {}
}
