<div class="full-page w-100 px-0">
  <!--Left Side-->
  <img
    class="shared-login-banner d-md-block d-none"
    draggable="false"
    src="assets/images/login-registration/login-banner-v2.jpg"
  />
  <div class="bg-primary d-flex w-100 d-md-none m-auto mobile-header"></div>
  <!--Right Side-->
  <div class="right-side-login">
    <div class="d-flex justify-content-center d-lg-none bg-primary align-items-center logo-container">
      <img alt="" class="register-logo" src="assets/images/logo-v2.png" draggable="false" />
    </div>
    <form class="reset-password container" (submit)="submit()" [formGroup]="formReset">
      <h5 class="reset-password-title">{{ 'reset.pw.title' | translate }}</h5>
      <p class="font-size-14">{{ 'user.reset.password.instruction' | translate }}</p>
      <div class="form-floating mb-3">
        <input class="form-control col-12 col-lg-9 me-2" formControlName="email" />
        <label>{{ 'reset.pw.label' | translate }}</label>
      </div>
      <p class="font-size-14 instruction">{{ 'user.reset.password.message' | translate }}</p>
      <div class="m-0 row action-buttons mt-auto">
        <button [disabled]="formReset.invalid" type="submit" class="btn btn-primary col-12">
          {{ 'reset.pw.btn' | translate }}
        </button>
        <div class="text-danger font-size-12 mt-2" [style.visibility]="checkForm['email'] ? '' : 'hidden'">
          {{ 'reset.pw.warn' | translate }}
        </div>
      </div>
    </form>
  </div>
</div>

<ng-template #alert let-modal>
  <div class="modal-body">
    <div class="text-center mt-4">{{ contentModal }}</div>
  </div>
  <div class="modal-footer border-top-0">
    <button type="button" class="btn btn-success" (click)="modal.close()" [routerLink]="['/login']">
      {{ 'reset.pw.confirm.button' | translate }}
    </button>
  </div>
</ng-template>
