<div class="full-page w-100 px-0">
  <!--Left Side-->
  <div class="left-side">
    <img
      class="shared-login-banner d-md-block d-none"
      draggable="false"
      src="assets/images/login-registration/login-banner-v2.jpg"
    />
  </div>
  <div class="bg-primary d-flex w-100 d-md-none m-auto mobile-header"></div>
  <!--Right Side-->
  <div class="right-side-login">
    <div class="login-container">
      <h2 class="text-primary text-uppercase mb-2 mb-lg-0">{{ 'login.page.title' | translate }}</h2>
      <div class="text-black panel-text mlb-text font-size-14">
        {{ 'login.page.description' | translate }}
      </div>
      <fgb-user-login></fgb-user-login>
    </div>
  </div>
</div>
