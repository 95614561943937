<nav class="nav nav-secondary mixed-loyalty-nav my-0">
  <a
    attr.aria-label="{{ 'rewards.nav.featured.screenreader' | translate }}"
    class="nav-item"
    [routerLink]="['/rewards']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <div class="icon material-icons" aria-hidden="true">redeem</div>
    <label>{{ 'rewards.nav.featured' | translate }}</label>
  </a>
  <a
    class="nav-item d-none d-lg-block"
    [routerLink]="['/rewards/wallet']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    attr.aria-label="{{ 'rewards.nav.wallet.screenreader' | translate : { fgbdefault: 'Move to Wallet page' } }}"
  >
    <div class="icon material-icons" aria-hidden="true">account_balance_wallet</div>
    <label>{{ 'rewards.nav.wallet' | translate }}</label>
  </a>
</nav>
