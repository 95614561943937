import { Component, OnInit, OnDestroy } from '@angular/core';
import { FantasyGameQuery, FantasyGame, FantasyLeaderboard } from '@fgb/core';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import * as _moment from 'moment';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { OwlCarouselSettings } from 'src/app/shared/utilities/owl-carousel-settings';
import { FantasyService, MonthlyLeaderboard } from 'src/app/modules/fantasy/fantasy-leaderboard.service';

const moment = _moment;

@Component({
  selector: 'fgb-fantasy-games-page',
  templateUrl: './fantasy-games-page.component.html',
  styleUrls: ['./fantasy-games-page.component.scss'],
})
export class FantasyGamesPageComponent implements OnInit, OnDestroy {
  currentGames$: Observable<FantasyGame[]>;
  pendingGames$: Observable<FantasyGame[]>;
  pendingPeriod: number = 48;

  yearlyLeaderboard$: Observable<FantasyLeaderboard[]>;
  monthlyLeaderboard$: Observable<MonthlyLeaderboard[]>;

  showMonthly: boolean = true;
  year: number = new Date().getFullYear();

  customOptions: OwlOptions = {
    items: 1,
    margin: OwlCarouselSettings.Margin,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    navSpeed: 700,
    center: false,
    nav: true,
    dots: false,
    startPosition: this.fantasyService.monthSlideIndex,
    navText: ['<i class="material-icons fw-bold">chevron_left</i>', '<i class="material-icons fw-bold">chevron_right</i>'],
    stagePadding: 0,
  };
  customGameOptions: OwlOptions = {
    items: 1,
    autoHeight: true,
    autoWidth: true,
    margin: OwlCarouselSettings.Margin,
    autoplay: OwlCarouselSettings.Autoplay,
    autoplayTimeout: OwlCarouselSettings.AutoplayTimeout,
    autoplaySpeed: OwlCarouselSettings.AutoplaySpeed,
    navSpeed: 700,
    responsive: {
      900: { autoWidth: false, autoHeight: false },
    },
  };

  fantasySub: Subscription;

  constructor(private fantasyQuery: FantasyGameQuery, private router: Router, private fantasyService: FantasyService) {}

  ngOnInit() {
    this.currentGames$ = this.fantasyQuery.selectCurrentGames();
    this.pendingGames$ = this.fantasyQuery.selectFinshedFantasyGamesAfterDate(moment.utc().subtract(this.pendingPeriod, 'hours'));

    this.yearlyLeaderboard$ = this.fantasyService.getYearlyLeaderboard(3, true);
    this.monthlyLeaderboard$ = this.fantasyService.getMonthlyLeaderboard(3, true);
  }

  ngOnDestroy() {
    if (this.fantasySub) {
      this.fantasySub.unsubscribe();
    }
  }

  goToDetail(gameId: number) {
    this.router.navigate([`games/pickaplayer/${gameId}`]);
  }

  hasActivePlayer(game: FantasyGame) {
    return game.GamePlayers.some((g) => g.IsSelected);
  }
}
