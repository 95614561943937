<div class="input-password form-floating">
  <input
    class="form-control"
    [class.invalid]="!control?.valid && control?.touched"
    [placeholder]="placeholder"
    name="input-password"
    [attr.type]="inputType()"
    [ngModel]="innerValue()"
    (input)="writeValue($event.target.value)"
  />
  <label class="text-info">{{ label }}</label>
  <span class="toggle-password-visibility material-icons-outlined text-info" (click)="toggleVisibility()">
    <ng-container [ngSwitch]="inputType()">
      <ng-container *ngSwitchCase="'password'">visibility_off</ng-container>
      <ng-container *ngSwitchCase="'text'">visibility</ng-container>
    </ng-container>
  </span>
</div>
