import { Component, OnInit, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MemberQuery, ProviderName } from '@fgb/core';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';
import { map } from 'rxjs/operators';
import { Regex } from 'src/app/shared/utilities/regex';

@Component({
  selector: 'fgb-account-details-page',
  templateUrl: './account-details-page.component.html',
  styleUrls: ['./account-details-page.component.scss'],
})
export class AccountDetailsPageComponent implements OnInit {
  name: Signal<string | undefined> = toSignal(
    this.memberQuery.selectMemberDetails().pipe(map((m) => (!!m ? `${m.FirstName} ${m.Surname}` : '')))
  );
  screenType = ScreenType;
  regex = Regex;
  provider = ProviderName.PayTech;

  constructor(private memberQuery: MemberQuery) {}

  ngOnInit() {}
}
