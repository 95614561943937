<div class="fantasy-panel rounded border overflow-hidden">
  <div class="row mx-0">
    <div class="col-12 px-0 bg-primary" [ngClass]="{ 'col-lg-7': horizontal }">
      <div class="game-summary text-white">
        <div class="d-none d-lg-block">
          <div class="text-center line-height-18">
            <p class="mb-2 font-size-12">{{ 'pick.player.time.left' | translate }}</p>
            <fgbcl-countdown class="font-size-16 fw-bold" [endDateTime]="game.EndDate"></fgbcl-countdown>
          </div>
          <div class="row team-vs-team d-flex justify-content-center align-items-center mx-0" [ngClass]="{ 'my-4': horizontal }">
            <div class="team home col-4">
              <img
                *ngIf="game.HomeTeamLogo"
                class="d-flex ms-auto me-auto mt-3 mt-lg-0"
                src="{{ game.HomeTeamLogo | contentImage: '7' }}"
              />
            </div>
            <div class="versus col-4 text-center">
              <div class="font-page-heading font-size-20">{{ 'games.pickaplayer.vs' | translate }}</div>
            </div>
            <div class="team away col-4">
              <img
                *ngIf="game.AwayTeamLogo"
                class="d-flex ms-auto me-auto mt-3 mt-lg-0"
                src="{{ game.AwayTeamLogo | contentImage: '7' }}"
              />
            </div>
          </div>
          <div class="line-height-18 font-size-14 text-center mt-5">
            <div class="date font-size-12">{{ gameDate }}, {{ gameTime }}</div>
          </div>
        </div>
        <div class="d-block d-lg-none">
          <div class="row">
            <div class="col-4 line-height-18 pe-0">
              <p class="mb-lg-0 mb-1 font-size-12 mt-lg-0 mt-3">{{ 'pick.player.time.left' | translate }}</p>
              <fgbcl-countdown class="font-size-16 fw-bold" [endDateTime]="game.EndDate"></fgbcl-countdown>
            </div>
            <div class="d-flex flex-column col-8 ps-0">
              <div class="row team-vs-team d-flex justify-content-center align-items-center mx-0">
                <div class="team home col-4">
                  <img *ngIf="game.HomeTeamLogo" class="d-flex mx-auto" src="{{ game.HomeTeamLogo | contentImage: '6' }}" />
                </div>
                <div class="versus col-4 text-center">
                  <div class="font-page-heading font-size-20">{{ 'games.pickaplayer.vs' | translate }}</div>
                </div>
                <div class="team away col-4">
                  <img *ngIf="game.AwayTeamLogo" class="d-flex mx-auto" src="{{ game.AwayTeamLogo | contentImage: '6' }}" />
                </div>
              </div>
              <div class="line-height-18 font-size-12 text-center">
                <div class="dates">{{ gameDate }}, {{ gameTime }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 py-2 px-3" [ngClass]="{ 'col-lg-5': horizontal }">
      <div class="d-none d-lg-block">
        <div class="player-select text-center">
          <div class="player-image mx-auto rounded-circle border overflow-hidden">
            <img
              *ngIf="selectedPlayer && selectedPlayer.Image"
              src="{{ selectedPlayer.Image | contentImage: '6' }}"
              class="w-100 h-100 m-auto"
            />
          </div>

          <fgb-fantasy-player-select-confirm
            [isGamePending]="isGamePending"
            [game]="game"
            [buttonState]="buttonState"
            [selectedPlayer]="selectedPlayer"
            (button)="buttonClick()"
          >
          </fgb-fantasy-player-select-confirm>
        </div>
      </div>
      <div class="d-block d-lg-none">
        <div class="player-select text-center">
          <div class="row">
            <div class="col-4">
              <div class="player-image mx-auto rounded-circle border overflow-hidden">
                <img
                  *ngIf="selectedPlayer && selectedPlayer.Image"
                  src="{{ selectedPlayer.Image | contentImage: '6' }}"
                  class="w-100 h-100 m-auto"
                />
              </div>
            </div>
            <div class="col-8">
              <fgb-fantasy-player-select-confirm
                [isGamePending]="isGamePending"
                [game]="game"
                [buttonState]="buttonState"
                [selectedPlayer]="selectedPlayer"
                (button)="buttonClick()"
              >
              </fgb-fantasy-player-select-confirm>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
