<div class="px-4 pt-3 mb-3">
  <div class="row mb-2 fw-bold font-size-12">
    <div class="col-2">{{ 'pick.player.leaderboard.rank' | translate }}</div>
    <div class="col-6">{{ 'pick.player.leaderboard.name' | translate }}</div>
    <div class="col-4 text-end">{{ 'pick.player.leaderboard.points' | translate }}</div>
  </div>
  <div
    class="font-size-14 mb-2 fantasy-row"
    *ngFor="let entry of fantasyEntries; let i = index"
    [ngClass]="{ 'current-user-row': entry.IsCurrentUser, 'out-top': entry.IsCurrentUser && entry.Position > topNumber }"
  >
    <div class="row my-2" [ngClass]="{ 'rounded bg-primary text-white py-2 mb-2 current-user': entry.IsCurrentUser }">
      <div class="col-2 fw-bold font-size-12">
        {{ entry.Position }}
      </div>
      <div class="col-6 text-capitalize font-size-12" [ngClass]="{ 'text-info': !entry.IsCurrentUser }">
        {{ entry.FullName }}
      </div>
      <div class="col-4 font-size-12 text-end" [ngClass]="{ 'text-info': !entry.IsCurrentUser }">
        {{ entry.Points / 100 }}
      </div>
    </div>
  </div>
</div>
