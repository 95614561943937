<div *ngIf="purchasedItemList && purchasedItemList.length > 0; else noPurchase">
  <div class="d-block d-lg-flex mt-lg-4 mt-1 justify-content-center align-items-center">
    <div class="item-details d-flex flex-column align-items-center flex-grow-1 flex-shrink-1 p-3">
      <div class="d-lg-flex d-block">
        <div>
          <div class="text-success mb-1 fw-bold font-size-18 text-left">
            <ng-container *ngIf="marketplaceItemType == 'Marketplace'">{{
              'marketplace.purchase.success' | translate
            }}</ng-container>
            <ng-container *ngIf="marketplaceItemType == 'Event'">
              <span class="text-success">
                {{ 'events.purchase.success' | translate }}
              </span>
            </ng-container>
            <ng-container *ngIf="marketplaceItemType == 'Product'">
              <span class="text-success">
                {{ 'products.purchase.success' | translate }}
              </span>
            </ng-container>
            <ng-container *ngIf="marketplaceItemType == 'MerchantValue'">
              <span class="text-success">
                {{ 'marketplace.purchase.merchant.success' | translate }}
              </span>
            </ng-container>
          </div>
          <p class="mb-0 text-black font-size-14 text-left pe-lg-4 rewards-success-description">
            <ng-container *ngIf="marketplaceItemType == 'Marketplace'">{{
              'marketplace.purchase.email.confirmation' | translate
            }}</ng-container>
            <ng-container *ngIf="marketplaceItemType == 'Event'">{{
              'events.purchase.email.confirmation' | translate
            }}</ng-container>
            <ng-container *ngIf="marketplaceItemType == 'Product'">{{
              'products.purchase.email.confirmation' | translate
            }}</ng-container>
            <ng-container *ngIf="marketplaceItemType == 'MerchantValue'">{{
              'merchant.purchase.email.confirmation' | translate
            }}</ng-container>
          </p>

          <div class="d-flex justify-content-between border-0 mt-4">
            <a class="btn btn-primary confirm-btn fw-bold" [routerLink]="['/rewards/wallet']">{{
              'rewards.back.to.wallet' | translate
            }}</a>
          </div>
        </div>

        <div class="border rounded payment-details">
          <div *ngFor="let purchasedItem of purchasedItemList">
            <div class="d-lg-flex d-block pb-lg-2">
              <div class="mt-lg-3 me-4">
                <img
                  class="purchase-success-image rounded"
                  src="{{ purchasedItem.ItemImageURL || defaultItemImageUrl | contentImage : '7' : 'marketplace' }}"
                  alt="{{ purchasedItem.ImageURL }}"
                  [useDefault]="true"
                  loyaltyType="marketplace"
                  draggable="false"
                />
              </div>
              <div class="mt-3 mb-lg-0 mb-5">
                <ng-container>
                  <div class="font-size-14 fw-bold mb-3">
                    {{ purchasedItem.DisplayName || defaultItemName || 'Display Name' }}
                  </div>
                </ng-container>
                <!--Attributes-->
                <ng-container *ngFor="let attribute of attributes">
                  <div class="d-flex align-items-center mt-2">
                    <span class="font-size-12 rewards-details-spacing">{{ attribute.AttributeName }}:</span>
                    <span class="fw-bold font-size-14">{{ attribute.ValueName }}</span>
                  </div>
                </ng-container>
                <!--Custom Fields-->
                <ng-container *ngFor="let customfield of customFields">
                  <div class="d-flex align-items-center mt-2">
                    <span class="font-size-12 rewards-details-spacing">{{ customfield.DisplayName }}:</span>
                    <span class="fw-bold font-size-14"> {{ customfield.Value }}</span>
                  </div>
                </ng-container>
                <!--Quantity-->
                <div class="d-flex align-items-center mt-2">
                  <span class="font-size-12 rewards-details-spacing">{{ 'marketplace.quantity' | translate }}</span>
                  <span class="fw-bold font-size-14">{{ quantity || purchasedItem.ItemCount }}</span>
                </div>
                <!--Merchant-->
                <ng-container *ngIf="marketplaceItemType == 'MerchantValue'">
                  <div class="d-flex align-items-center mt-2" *ngIf="purchasedItem.TotalMerchantPrice > 0">
                    <span class="font-size-12 rewards-details-spacing">{{ 'marketplace.merchant.used' | translate }}</span>
                    <span class="fw-bold pe-2 font-size-14">{{
                      purchasedItem.TotalMerchantPrice / 100 | FortressCurrencyPipe
                    }}</span>
                  </div>
                </ng-container>
                <!--Price-->
                <ng-container *ngIf="marketplaceItemType != 'MerchantValue'">
                  <div id="points-value" class="d-flex align-items-center mt-2" *ngIf="purchasedItem.EcashValue <= 0">
                    <span class="font-size-12 rewards-details-spacing">{{ 'marketplace.points.used' | translate }}</span>
                    <span class="fw-bold font-size-14"
                      >{{ price | number }} {{ 'marketplace.points.value.pts' | translate }}</span
                    >
                  </div>
                </ng-container>
                <!--Ecash-->
                <div class="col-6" *ngIf="purchasedItem.EcashValue > 0">
                  <div class="mb-2 d-flex align-items-center">
                    <span class="mt-3 font-size-12">{{ 'marketplace.ecash.used' | translate }}</span>
                  </div>
                </div>
                <div class="col-6" *ngIf="purchasedItem.EcashValue > 0">
                  <div class="mb-2 d-flex align-items-center">
                    <span class="font-size-14 pe-2 fw-bold">{{
                      (quantity * purchasedItem.EcashValue) / 100 | FortressCurrencyPipe
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div *ngFor="let purchasedItem of purchasedItemList">
              <div class="mx-auto mb-2 mt-3 border-0" *ngIf="purchasedItem.ProcessProviderId == ProcessProvider.Download">
                <a
                  class="d-flex align-items-center justify-content-center btn btn-secondary w-100 confirm-btn fw-bold text-white"
                  (click)="downloadPurchasedItem(purchasedItemLogId)"
                >
                  <span class="font-size-14">{{ 'download.rewards.item' | translate }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #noPurchase>
  <div class="text-center m-5">{{ 'no.purchased.item.found' | translate }}</div>
</ng-template>
