<div class="banner justify-content-center d-none d-lg-flex" role="banner">
  <h1 class="text-white">{{ 'cart.checkout.page.portal.products.title' | translate }}</h1>
</div>
<div class="container">
  <div class="back-mobile-bar d-flex justify-content-between align-items-center d-lg-none my-2">
    <!-- Back Button -->
    <button class="btn text-primary fw-bold d-block d-lg-none ps-0 go-back-btn" (click)="goBack()">
      <span class="font-size-14 text-go-back">{{ 'cart.goBack' | translate }}</span>
    </button>
    <!-- Checkout Title -->
    <div class="">
      <h4 class="mb-0 text-black font-size-14 text-uppercase">{{ 'cart.checkout.page.heading' | translate }}</h4>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-7 col-12 mt-lg-4 mt-3">
      <h5 class="text-black fw-bold checkout-summary-title">{{ 'cart.itemsSummary' | translate }}</h5>
      <div class="mb-4 mt-4">
        <fgbcl-cart-item [isCheckoutPage]="true"></fgbcl-cart-item>
      </div>
      <button class="btn text-primary fw-bold d-none d-lg-block ps-0 go-back-btn" (click)="goBack()">
        <span class="font-size-14 text-go-back">{{ 'cart.goBack' | translate }}</span>
      </button>
    </div>
    <div class="col-lg-5 col-12 mt-4">
      <!--member details address-->
      <div class="container border rounded py-3 mb-3" [ngClass]="{ 'd-none': editingMemberAddress }">
        <div class="position-relative rounded">
          <div class="d-flex justify-content-between align-items-center mb-2">
            <h4 class="text-secondary font-size-14 mb-0">{{ 'checkout.shipping.address.heading' | translate }}</h4>
            <div class="text-primary cursor-pointer font-size-14 fw-bold" (click)="editMemberAddress()">
              {{ 'checkout.shipping.edit' | translate }}
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <span *ngIf="memberDetail$ | async as member" class="memberDetail font-size-14 mb-0 d-flex">
                <div>
                  {{ member.Street }} <span *ngIf="member.Town">,</span> {{ member.Town }} <span *ngIf="member.PostCode">,</span>
                  {{ member.PostCode }} <span *ngIf="member.Country">,</span>
                  {{ member.Country }}
                  <span *ngIf="member.County">,</span>
                  {{ member.County }}
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
      <!--EO member details address-->
      <!-- EDIT MEMBER DETAILS ADDRESS FORM -->
      <ng-container *ngIf="editingMemberAddress">
        <div class="container mb-3 py-2 border rounded-4">
          <div class="position-relative">
            <div class="d-flex justify-content-between">
              <h4 class="text-secondary font-size-14 pt-2 mb-3">{{ 'checkout.shipping.address.heading' | translate }}</h4>
              <a
                (click)="clearAddressForm(memberAddressForm)"
                class="text-primary heading-text-one mb-1 pt-1 font-size-14 cursor-pointer fw-bold"
                >{{ 'address.clear.btn' | translate }}</a
              >
            </div>
            <form [formGroup]="memberAddressForm" class="font-size-12">
              <!--street-->
              <div class="form-floating">
                <input
                  id="Street"
                  placeholder="{{ 'address.addressname' | translate }}"
                  class="form-control"
                  name="Street"
                  type="text"
                  formControlName="Street"
                  [ngClass]="{
                    'is-invalid': hasSubmittedMemberAddress && !!memberAddressForm.controls.Street.errors,
                  }"
                  required
                />
                <label for="AddressName" class="font-default">
                  <span>{{ 'address.addressname' | translate }}</span>
                </label>
                <div class="invalid-feedback">{{ 'address.missing.addressname' | translate }}</div>
              </div>
              <!--city-->
              <div class="form-floating">
                <input
                  id="Town"
                  class="form-control"
                  name="Town"
                  type="text"
                  formControlName="Town"
                  placeholder="{{ 'address.city' | translate }}"
                  [ngClass]="{
                    'is-invalid': hasSubmittedMemberAddress && !!memberAddressForm.controls.Town.errors,
                  }"
                  required
                />
                <label for="Town" class="font-default">
                  <span>{{ 'address.city' | translate }}</span>
                </label>
                <div class="invalid-feedback">{{ 'address.missing.city' | translate }}</div>
              </div>
              <!--country and region-->
              <fgbcl-country-and-regions
                [SeparateRow]="true"
                [Form]="memberAddressForm"
                [RegionLabel]="'State'"
                [HasSubmitted]="hasSubmittedMemberAddress"
              ></fgbcl-country-and-regions>
              <!--post number-->
              <div class="form-floating">
                <input
                  id="PostCode"
                  class="form-control"
                  name="PostCode"
                  type="text"
                  formControlName="PostCode"
                  [ngClass]="{
                    'is-invalid': hasSubmittedMemberAddress && !!memberAddressForm.controls.PostCode.errors,
                  }"
                  required
                  placeholder="{{ 'address.zip.code' | translate }}"
                />
                <label for="PostCode" class="font-default">
                  <span>{{ 'address.zip.code' | translate }}</span>
                </label>
                <div class="invalid-feedback">
                  {{ 'address.missing.zip.code' | translate }}
                </div>
              </div>
            </form>
            <div class="d-flex justify-content-end mt-3 pb-1 font-size-14">
              <button class="btn btn-cancel fw-bold btn-cancel-save me-3" (click)="cancelAndRebuildForm()">
                {{ 'address.cancel.btn' | translate }}
              </button>
              <button class="btn btn-success fw-bold btn-cancel-save" (click)="saveMemberDetailAddress()">
                {{ 'address.save.btn' | translate }}
              </button>
            </div>
          </div>
        </div>
      </ng-container>
      <!--EO edit member details address-->

      <!--summary of items in basket-->
      <ng-container *ngIf="items$ | async as items">
        <div class="container address-container p-3 rounded">
          <label class="text-black fw-bold font-size-14 mb-3">{{ 'cart.summary' | translate }}</label>
          <div class="row">
            <div class="col-6 font-size-14">
              <span>{{ 'cart.summary.items' | translate }}</span>
            </div>
            <div class="col-6 font-size-12 text-end pr-4">{{ totalItems$ | async }}</div>
          </div>
          <hr class="w-100" />
          <div class="d-flex justify-content-between align-items-center">
            <ng-container *ngIf="isListHasMerchantItem">
              <div class="fw-bold font-size-14">{{ 'cart.summary.totalMerchant' | translate }}</div>
              <div class="d-flex align-items-center">
                <div class="col-6 fw-bold font-size-14 text-end">{{ totalPriceMerchantValue / 100 | FortressCurrencyPipe }}</div>
              </div>
            </ng-container>
            <ng-container *ngIf="!isListHasMerchantItem">
              <div class="fw-bold font-size-14">{{ 'cart.summary.totalPoint' | translate }}</div>
              <div class="col-6 fw-bold font-size-14 text-end">{{ totalPoints | FortressCurrencyPipe : 'PTS' }}</div>
            </ng-container>
          </div>
        </div>
        <ng-container *ngIf="defaultCard()?.CardNumber; else noCard">
          <div class="payment-type-container rounded">
            <div class="d-flex justify-content-between align-items-center">
              <div class="title d-flex flex-fill">
                <label class="font-size-14">{{ 'cart.payment_type' | translate }}</label>
                <!-- <a class="change-card text-primary ms-auto" *ngIf="!isEdit" (click)="isEdit = true">{{
                  'cart.change_card_payment' | translate
                }}</a> -->
              </div>
            </div>
            <ng-container *ngIf="isEdit; else default">
              <label class="text-primary mb-2">Linked Cards</label>
              <ng-container *ngFor="let card of cards">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="radio-btn"
                    id="radio-{{ card?.TokenId }}"
                    [value]="card?.TokenId"
                    [selected]="defaultCard()?.TokenId === card?.TokenId"
                  />
                  <label class="form-check-label font-size-14" for="radio-{{ card?.TokenId }}">{{
                    'cart.card_infor' | translate
                  }}</label>
                  <ng-container *ngTemplateOutlet="cardDetail; context: { $implicit: card }"></ng-container>
                </div>
              </ng-container>
              <hr />

              <button class="btn btn-primary fw-bold w-100 paynow-btn text-white">Pay Using New Payment Card</button>
              <hr />
              <div class="action-buttons d-flex justify-content-center">
                <button class="btn btn-danger fw-bold w-100 cancel-btn text-white" (click)="isEdit = false">Cancel</button>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #noCard>
  <a class="btn btn-primary text-white font-weight-bold mt-4 w-100 font-size-14" [routerLink]="'/account'">
    {{ 'checkout.no.card.linked' | translate }}
  </a>
</ng-template>

<ng-template #default>
  <div class="mt-2">
    <ng-container *ngTemplateOutlet="cardDetail; context: { $implicit: defaultCard() }"></ng-container>
    <div class="px-0 mt-2">
      <button
        class="btn btn-primary fw-bold mt-2 w-100 paynow-btn text-white d-flex justify-content-center align-items-center"
        (click)="payNow()"
        [disabled]="
          !validCheckout ||
          totalPoints > currentBalance ||
          !defaultCard()?.CardNumber ||
          isSubmitted === false ||
          editingMemberAddress ||
          memberAddressForm?.invalid
        "
      >
        @if(isSubmitted === false){
        <div class="ms-2">
          <fgbcl-loading size="small"></fgbcl-loading>
        </div>

        }@else {

        {{ statusText }}
        }
      </button>
    </div>
    <fgbcl-errors></fgbcl-errors>
  </div>
</ng-template>

<ng-template #cardDetail let-item>
  <div class="card-infor">
    <div class="card-info d-flex align-items-center">
      <img alt="" class="card-type-image" src="{{ item?.CardType | cardTypeImage }}" />
      <span class="card-number mb-0">**** {{ item?.CardNumber.substr(12) }}</span>
    </div>
  </div>
</ng-template>
