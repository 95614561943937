import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { MemberDetails, MemberQuery, MemberService } from '@fgb/core';
import { tap } from 'rxjs/operators';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { FGBValidators } from '@fgb/portal-component-library/src/lib/shared/validators';

@Component({
  selector: 'fgb-edit-account-details',
  templateUrl: './edit-account-details.component.html',
  styleUrls: ['./edit-account-details.component.scss'],
})
export class EditAccountDetailsComponent implements OnInit {
  member$: Observable<MemberDetails | undefined>;
  isInEditMode = false;
  portalId = '';
  detailsForm: UntypedFormGroup;
  isLoading = false;
  @Output() actionDone = new EventEmitter<boolean>();
  @Input() autoEditMode = false;
  maxYear: number;
  currentDate: number;
  currentMonth: number;
  baseData: any;

  constructor(private memberQuery: MemberQuery, private memberService: MemberService, private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.member$ = this.memberQuery.selectMemberDetails().pipe(
      tap((z) => {
        if (z) {
          this.portalId = z.PortalId;
          const dob = this._getNgbDateFromString(z.DOB.toString());
          this.detailsForm = this.formBuilder.group({
            addressName: [z.AddressName],
            emailAddress: [z.EmailAddress1, Validators.email],
            dateOfBirth: [{ value: dob, disabled: !this.autoEditMode }],
            street: [z.Street],
            town: [z.Town],
            county: [z.County],
            country: [z.Country],
            postcode: [z.PostCode],
            firstName: [z.FirstName, [Validators.required, Validators.pattern("^[a-zA-Z ,.'-]+$"), FGBValidators.Empty]],
            surname: [z.Surname, [Validators.required, Validators.pattern("^[a-zA-Z ,.'-]+$"), FGBValidators.Empty]],
          });
          this.baseData = { ...this.detailsForm.value, dateOfBirth: dob };
        }
      })
    );

    this.defaultDatePickerSettings();
  }

  edit() {
    this.isInEditMode = true;
    this.detailsForm.get('dateOfBirth')?.enable();
  }

  stopEditing() {
    this.isInEditMode = false;
    this.detailsForm.patchValue(this.baseData);
    this.detailsForm.get('dateOfBirth')?.disable();
  }

  notInAnyEditMode() {
    return !this.isInEditMode && !this.autoEditMode;
  }

  anyEditMode() {
    return this.isInEditMode || this.autoEditMode;
  }

  disableIfFormIncomplete(): string {
    const formComplete: Boolean =
      this.detailsForm.controls.addressName.value !== '' &&
      this.detailsForm.controls.emailAddress.value !== '' &&
      this.detailsForm.controls.dateOfBirth.value !== null;
    const formValid: Boolean = this.detailsForm.valid;
    if (formComplete && formValid) {
      return '';
    } else {
      return 'disabled';
    }
  }

  save() {
    this.detailsForm.markAllAsTouched();

    if (this.detailsForm.valid) {
      this.isLoading = true;
      let memberDetails = {
        PortalId: this.portalId,
        EmailAddress1: this.detailsForm.controls.emailAddress.value,
        AddressName: this.detailsForm.controls.addressName.value,
        DOB: this._getStringFromNgbDate(this.detailsForm.controls.dateOfBirth.value),
        Street: this.detailsForm.controls.street.value,
        Town: this.detailsForm.controls.town.value,
        Country: this.detailsForm.controls.country.value,
        County: this.detailsForm.controls.county.value,
        PostCode: this.detailsForm.controls.postcode.value,
        FirstName: this.detailsForm.controls.firstName.value,
        Surname: this.detailsForm.controls.surname.value,
      } as MemberDetails;

      this.memberService.updateMemberDetails(this.portalId, memberDetails);
      this.isInEditMode = false;
      this.isLoading = false;
      this.actionDone.emit(true);
    }
  }

  /** Gets a date string in ISO format from an NgbDate. */
  private _getStringFromNgbDate(ngbDate: NgbDate) {
    if (ngbDate) {
      let date = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day, 12);
      date.setUTCHours(0);
      date.setUTCMinutes(0);
      date.setUTCSeconds(0);
      return date.toISOString();
    } else {
      return '';
    }
  }

  /** Gets an NgbDate from a date string. */
  private _getNgbDateFromString(dateString: string): NgbDate | null {
    if (dateString) {
      const date = new Date(dateString);
      if (date) {
        return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
      }
    }
    return null;
  }

  /* this should be configured according
   * to minimum age set by club to be
   * able to sign up to portal.
   */
  defaultDatePickerSettings() {
    this.maxYear = new Date().getFullYear() - 16;
    this.currentDate = new Date().getDate();
    this.currentMonth = new Date().getMonth() + 1;
  }
}
