import { CommonModule } from '@angular/common';
import { Component, OnInit, Signal, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { AbstractControl, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { FgbError, MemberDetails, MemberQuery } from '@fgb/core';
import { FGBSharedModule } from '@fgb/portal-component-library/src/lib/shared';
import { InputPasswordComponent } from '../../../../shared/components/input-password/input-password.component';
import { UserLoginDetails, UserDetailsService } from './user-details.service';
import { PASSWORD_PATTERN } from 'src/app/shared/utilities/globals';
import { ErrorComponent } from '@fgb/portal-component-library/src/lib/shared/errors';

@Component({
  selector: 'fgb-edit-login-details',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, FGBSharedModule, InputPasswordComponent, ErrorComponent],
  templateUrl: './edit-login-details.component.html',
  styleUrl: './edit-login-details.component.scss'
})
export class EditLoginDetailsComponent implements OnInit{
  private memberQuery: MemberQuery = inject(MemberQuery);
  private fb: FormBuilder = inject(FormBuilder);
  private userDetailsService: UserDetailsService = inject(UserDetailsService);
  editLoginDetailsForm: FormGroup;
  memberDetails: Signal<MemberDetails | undefined> = toSignal(this.memberQuery.selectMemberDetails(), { initialValue: undefined });
  isEdit: boolean = false;
  baseData: UserLoginDetails;

  ngOnInit() {
    this.baseData = new UserLoginDetails(this.memberDetails());
    this._init();
  }

  private _init(): void{
    this.editLoginDetailsForm = this._initializeForm(this.baseData);
  }


  private _initializeForm(memberDetails?: UserLoginDetails): FormGroup {
    return this.fb.group({
      EmailAddress: [memberDetails?.EmailAddress || '', [Validators.required]],
      Password: ['', [Validators.required ]],
      NewPassword: ['', [Validators.required, Validators.pattern(PASSWORD_PATTERN)]],
      ConfirmPassword: ['', [Validators.required]]
    }, { validators: [ validateSamePassword ], updateOn: 'submit' })
  };

  submit(): void{
    this.editLoginDetailsForm.markAllAsTouched();

    if( this.editLoginDetailsForm.valid ){

      const { Password, NewPassword, EmailAddress } = this.editLoginDetailsForm.value;
      const oldDetails = new UserLoginDetails({ ...this.memberDetails(), Password });
      const newDetails = new UserLoginDetails({ ...this.memberDetails(), EmailAddress, Password: NewPassword });

      this.userDetailsService.updateLoginDetails({ OldDetails: oldDetails, NewDetails: newDetails })
      .subscribe({
        next: () => {
          this.isEdit = false;
          this.baseData = new UserLoginDetails({ ClubId: newDetails.ClubId, Lcid: newDetails.Lcid, EmailAddress, Username: EmailAddress });
          this._init();
        },
        error: (err: any) => {
          this.isEdit = false;
          // Temporary fix the api is returning status 400 when successful, remove when fixed
          if( err.error.Message !== 'Success' ){
            throw new FgbError(err);
          }
        }
      });
    }
  }

  cancel(): void{
    this.isEdit = false;
    this._init();
  }


}

function validateSamePassword(control: AbstractControl): ValidationErrors | null {
  const password = control.get('NewPassword');
  const confirmPassword = control.get('ConfirmPassword');
  return password && confirmPassword && password.value === confirmPassword.value ? null : { 'passwordMismatch': true };
}
