<ng-container *ngIf="items$ | async as items">
  <div *ngIf="items.length">
    <ng-container *ngFor="let item of items">
      <div class="items-container d-flex">
        <div class="img-container">
          <div
            class="sub-item rounded text-center"
            [ngClass]="{
              'bg-primary': item.productData.ProductType == 1,
              'bg-secondary': item.productData.ProductType == 3
            }"
          >
            <img alt="" class="portal-product-img" src="{{ item.productData.PrimaryImage | contentImage : '6' }}" />
          </div>
        </div>
        <div class="item-description-container d-flex flex-column justify-content-center">
          <p class="font-size-14 mb-2">
            {{ item.productData.DisplayName }}
          </p>
          <div class="d-flex flex-column">
            <p class="font-size-12">
              <span class="fw-bold"><span [translate]="'checkout.cost'"></span>:</span>
              {{ item.productData.MerchantPrice / 100 | FortressCurrencyPipe : currencyCode }}
            </p>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div *ngIf="!items.length" class="font-size-14">
    <p [translate]="'checkout.empty-basket'">Your basket is empty!</p>
  </div>
</ng-container>
