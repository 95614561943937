import { Component, Input } from '@angular/core';
import { HideMobileNav } from '@fgb/portal-component-library/src/lib/directives';

@Component({
  selector: 'fgb-auction-detail-page',
  templateUrl: './auction-detail-page.component.html',
  styleUrls: ['./auction-detail-page.component.scss'],
  hostDirectives: [
    HideMobileNav
  ]
})
export class AuctionDetailPageComponent{
  @Input() id: number = 0;

}
