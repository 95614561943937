<div class="container">
  <div class="d-flex align-items-center mt-3 pt-3 pb-4">
    <!-- Back Button -->
    <div class="text-danger cursor-pointer back-button d-flex align-items-center" [routerLink]="'/rewards/wallet'">
      <span class="icon material-icons fw-bold pe-2">chevron_left</span>
      <span class="font-size-14 fw-bold">{{ 'claimed.back.to.wallet' | translate }}</span>
    </div>
    <!-- Title -->
    <label class="m-0 text-info wallet-redeemed-title font-size-14 text-uppercase">{{
      'wallet.redeemed.title' | translate
    }}</label>
    <div class="back-button"></div>
  </div>
  <ng-container
    *ngIf="
      {
        walletItemsClaimed: walletItemsClaimed$ | async
      } as walletItems;
      else noWalletItems
    "
  >
    <ng-container *ngIf="walletItems.walletItemsClaimed && walletItems.walletItemsClaimed.length; else noWalletItems">
      <fgbcl-rewards-wallet-redeemed-list [redeemedItems]="walletItems.walletItemsClaimed"></fgbcl-rewards-wallet-redeemed-list>
    </ng-container>
  </ng-container>
</div>

<ng-template #noWalletItems>
  <div class="container">
    <div class="no-items w-100 no-results-placeholder-div">
      <div class="no-results-placeholder">
        {{ 'claimed.rewards.placeholder.description' | translate }}
      </div>
    </div>
  </div>
</ng-template>
