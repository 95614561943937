<div class="survey-container rounded-4">
  <div class="survey-header text-center">
    <div
      class="image-container-polls w-100 overflow-hidden position-relative bg-poll rounded-top d-flex"
      *ngIf="survey?.ImageURL; else noImage"
    >
      <img class="w-100" src="{{ survey.ImageURL | contentImage : '7' }}" alt="{{ survey.ImageURL }}" />
    </div>
    <ng-template #noImage>
      <div class="no-image text-primary bg-poll">
        <h5 class="no-image-title text-uppercase">{{ 'survey.item.poll.title' | translate }}</h5>
      </div>
    </ng-template>
    <div class="survey-info">
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
          <div class="gIcon-polls text-primary material-icons inline-block my-0" aria-hidden="true">assessment</div>
        </div>
        <div class="px-3 d-none d-lg-block survey-question text-black font-size-14">{{ survey.SurveyQuestion }}</div>

        <div class="text-center fw-bold text-success font-size-16" [ngClass]="pointsWon > 0 ? 'd-block d-lg-none' : 'd-none'">
          +{{ pointsWon }} Pts
        </div>
        <div class="text-end d-flex align-items-center" *ngIf="!survey.HasBeenAnsweredByUser">
          <span class="material-icons align-middle pe-2 text-primary">schedule</span>
          <fgbcl-countdown-v2
            [endDateTime]="survey.EndDate"
            [startDateTime]="survey.RequestTime"
            class="font-size-14 text-primary"
          ></fgbcl-countdown-v2>
        </div>
      </div>
      <div class="d-block d-lg-none mt-3 text-start font-size-14 survey-question xs">{{ survey.SurveyQuestion }}</div>
    </div>
  </div>
  <ng-container *ngIf="survey.HasBeenAnsweredByUser; else answer">
    <fgb-survey-item-answer-completed-poll [survey]="survey" [pointsWon]="pointsWon"></fgb-survey-item-answer-completed-poll>
  </ng-container>
  <ng-template #answer>
    <fgb-survey-item-answer [survey]="survey"></fgb-survey-item-answer>
  </ng-template>
</div>
