<div *ngIf="currentSubscribedProduct && currentSubscribedProduct.ProductType == 1">
  <div class="rounded sub-card d-flex border">
    <div class="img-container me-3">
      <img alt="" class="product-image rounded" src="{{ currentSubscribedProduct.PrimaryImageURL | contentImage : '6' }}" />
    </div>
    <div class="d-flex product-description-container flex-column">
      <div class="product-header d-flex">
        <div
          class="font-size-14 fw-bold"
          [ngClass]="{
            'text-primary': currentSubscribedProduct.ProductType == 1,
            'text-secondary': currentSubscribedProduct.ProductType == 3
          }"
        >
          {{ currentSubscribedProduct.DisplayName }}
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <p class="font-size-12 subs-description" [innerHTML]="currentSubscribedProduct.Description"></p>
        </div>
        <a class="font-size-12 fw-bold text-black learn-more" (click)="openProductDescription(productInfo)">{{
          'subscription.list.item.learn.more' | translate
        }}</a>
      </div>
      <div class="action-buttons mt-auto">
        <div
          class="expiry-date-wrapper rounded"
          [class.expired]="currentSubscribedProduct.ProductType == 1 && currentSubscribedProduct && withinRenewal"
        >
          <span class="expiry-label font-size-12 fw-bold">{{
            (currentSubscribedProduct.ProductType == 1 && currentSubscribedProduct && withinRenewal
              ? 'subscription.list.item.expired.label'
              : 'subscription.list.item.expiry.label'
            ) | translate
          }}</span>
          <span class="ms-2 expiry-date font-size-12">{{ 'subscription.list.item.expired.date' | translate }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Product info modal-->
<ng-template #productInfo let-modal>
  <div class="text-black bg-white subscription-item-renewal-modal">
    <div class="modal-body d-flex flex-column align-items-center">
      <button class="material-icons modal-close-btn text-info" (click)="modal.dismiss('close')">cancel</button>
      <img
        alt=""
        class="info-product-image rounded my-2"
        src="{{ currentSubscribedProduct.PrimaryImageURL | contentImage : '6' }}"
      />
      <label class="font-size-14 mb-2">{{ currentSubscribedProduct.DisplayName }}</label>

      <p class="mb-4 mt-3 font-size-12 portal-product-modal-description" [innerHTML]="currentSubscribedProduct.Description"></p>
      <div class="text-center d-flex w-100 mt-3 justify-content-center">
        <div
          class="expiry-date-wrapper rounded"
          [class.expired]="currentSubscribedProduct.ProductType == 1 && currentSubscribedProduct && withinRenewal"
        >
          <span class="expiry-label font-size-12 fw-bold">{{
            (currentSubscribedProduct.ProductType == 1 && currentSubscribedProduct && withinRenewal
              ? 'subscription.list.item.expired.label'
              : 'subscription.list.item.expiry.label'
            ) | translate
          }}</span>
          <span class="ms-2 expiry-date font-size-12"> {{ 'subscription.list.item.expired.date' | translate }}</span>
        </div>
      </div>
    </div>
  </div>
</ng-template>
