import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { config } from '@fgb/core';
import { Observable } from 'rxjs';

export enum AccountActivationStatus {
  InActive,
  FirstTimeUser,
  Active,
}

@Injectable({
  providedIn: 'root',
})
export class AccountActivationService {
  private handler: HttpBackend = inject(HttpBackend);
  private http: HttpClient = new HttpClient(this.handler);

  constructor() {}

  validateActivationCode(activationCode: string): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('X-Fortress-ActivationKey', activationCode);
    return this.http.get(`${config.baseApiUrl}/api/activation`, { headers });
  }

  activateAccount(
    code: string,
    password: string,
    accountStatus: AccountActivationStatus = AccountActivationStatus.FirstTimeUser
  ): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('X-Fortress-Activationkey', code);
    return this.http.post(
      `${config.baseApiUrl}/api/activation/activate?accountStatus=${accountStatus}&Password=${password}`,
      {},
      { headers }
    );
  }
}
