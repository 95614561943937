<div class="mobile-nav-menu d-flex position-fixed flex-column d-lg-none">
  <!-- NAV BAR -->
  <div class="mobile-nav-bar d-flex justify-content-between bg-primary">
    <a class="nav-item fw-bold brand p-1" [routerLink]="['/']" routerLinkActive="router-link-active">
      <label id="homeLink" class="accessibility-only">Go To Home Page</label>
      <img alt="" class="home-logo" src="assets/images/logo-v2.png" draggable="false" />
    </a>
    <a class="nav-item text-white" [routerLink]="['/rewards']">
      <span class="icon material-icons material-mobile-nav" role="img" attr.aria-label="{{ 'nav.rewards' | translate }}"
        >redeem</span
      >
    </a>
    <a class="nav-item text-white" [routerLink]="['/challenges']">
      <span class="icon material-icons material-mobile-nav" role="img" attr.aria-label="{{ 'nav.challenges' | translate }}"
        >flag</span
      >
    </a>
    <!--Notifications-->
    <div class="nav-item">
      <fgb-notification-button></fgb-notification-button>
    </div>
    <button
      attr.aria-label="{{ 'nav.toggle.screenreader' | translate }}"
      class="nav-item fw-bold navbar-toggler text-white m-0 px-2"
      (click)="isCollapsed = !isCollapsed"
    >
      <span class="icon material-icons material-mobile-nav">menu</span>
    </button>
  </div>

  <!-- NAV SCREEN -->
  <div [ngbCollapse]="isCollapsed" class="mobile-nav-menu-dropdown bg-primary">
    <div class="d-flex flex-column p-1 mobile-nav-menu-content">
      <div class="container mt-3" *ngIf="memberDetails$ | async as member">
        <div class="member-card text-white">
          <!-- Close Button -->
          <button
            class="close-button position-absolute border-0"
            attr.aria-label="{{ 'modal.close' | translate }}"
            (click)="isCollapsed = !isCollapsed"
          >
            <i class="material-icons close-btn text-white">close</i>
          </button>
          <div class="logo-container mb-4">
            <img alt="" class="home-logo" src="assets/images/logo-v2.png" draggable="false" />
          </div>
          <div class="account-details">
            <div class="account-info">
              <h6 class="font-size-14 text-uppercase fw-bold">{{ member.FirstName + ' ' + member.Surname }}</h6>
              <div class="account-number-wrapper" *ngIf="memberCard$ | async as card">
                <span class="font-size-10"
                  >{{ card?.ProductCode | accountMembership | translate }} &nbsp;-&nbsp;<span>{{ card?.MemberId }}</span></span
                >
              </div>
            </div>
            <div class="language-container ms-auto">
              <fgbcl-language-button-select
                [hiddenLanguagesByLcid]="['4105', '1033', '1032', '1037', '3084', '1036']"
                [version]="2"
              />
            </div>
          </div>
        </div>
        <hr />
      </div>

      <!-- Nav Buttons -->
      <div class="mobile-nav-button-container">
        <a
          class="nav-item fw-bold large-item"
          [routerLink]="['/']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <div class="nav-item-title-div">
            <span class="icon material-icons me-2" aria-hidden="true">home</span>
            <div class="nav-text fw-bold">{{ 'nav.home' | translate }}</div>
          </div>
          <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
        </a>
        <a
          class="nav-item fw-bold large-item"
          [routerLink]="['/account']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <div class="nav-item-title-div">
            <span class="icon material-icons me-2" aria-hidden="true">person</span>
            <div class="nav-text fw-bold">{{ 'nav.account' | translate }}</div>
          </div>
          <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
        </a>

        <a
          class="nav-item"
          [routerLink]="['/rewards/wallet']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <div class="nav-item-title-div">
            <span class="icon material-icons me-2" aria-hidden="true">account_balance_wallet</span>
            <div class="nav-text fw-bold">{{ 'nav.wallet' | translate }}</div>
          </div>
          <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
        </a>
        <a class="nav-item" [routerLink]="['/rewards']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
          <div class="nav-item-title-div">
            <span class="icon material-icons me-2" aria-hidden="true">redeem</span>
            <div class="nav-text fw-bold">{{ 'nav.rewards' | translate }}</div>
          </div>
          <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
        </a>
        <a
          class="nav-item"
          [routerLink]="['/challenges']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <div class="nav-item-title-div">
            <span class="icon material-icons me-2" aria-hidden="true">flag</span>
            <div class="nav-text fw-bold">{{ 'nav.challenges' | translate }}</div>
          </div>
          <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
        </a>

        <a class="nav-item" [routerLink]="['/games']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
          <div class="nav-item-title-div">
            <span class="icon material-icons me-2" aria-hidden="true">extension</span>
            <div class="nav-text fw-bold">{{ 'nav.games' | translate }}</div>
          </div>
          <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
        </a>

        <a
          class="nav-item"
          [routerLink]="['/benefits']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <div class="nav-item-title-div">
            <span class="icon material-icons me-2" aria-hidden="true">hotel_class</span>
            <div class="nav-text fw-bold">{{ 'nav.benefits' | translate }}</div>
          </div>
          <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
        </a>

        <ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.newsletters'">
          <a
            class="nav-item"
            [routerLink]="['/newsletters']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <div class="nav-item-title-div">
              <span class="icon material-symbols-outlined me-2" aria-hidden="true">newsmode</span>
              <div class="nav-text fw-bold">{{ 'nav.newsletter' | translate }}</div>
            </div>
            <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
          </a>
        </ng-container>

        <ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.savings'">
          <a
            class="nav-item"
            [routerLink]="['/savings']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <div class="nav-item-title-div">
              <span class="icon material-icons me-2" aria-hidden="true">savings</span>
              <div class="nav-text fw-bold">{{ 'nav.savings' | translate }}</div>
            </div>
            <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
          </a>
        </ng-container>

        <a class="nav-item" [routerLink]="['/contact']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
          <div class="nav-item-title-div">
            <span class="icon material-icons me-2" aria-hidden="true">email</span>
            <div class="nav-text fw-bold">{{ 'nav.contact.us' | translate }}</div>
          </div>
          <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
        </a>

        <a class="nav-item" [routerLink]="['/terms']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
          <div class="nav-item-title-div">
            <span class="icon material-icons me-2" aria-hidden="true">article</span>
            <div class="nav-text fw-bold">{{ 'nav.terms' | translate }}</div>
          </div>
          <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
        </a>

        <a class="nav-item" [routerLink]="['/faq']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
          <div class="nav-item-title-div">
            <span class="icon material-icons me-2" aria-hidden="true">help</span>
            <div class="nav-text fw-bold">{{ 'nav.faq' | translate }}</div>
          </div>
          <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
        </a>

        <a class="nav-item logout-link-div" (click)="logout()">
          <span class="icon material-icons me-2" aria-hidden="true">power_settings_new</span>
          <div class="nav-text fw-bold">{{ 'nav.log.out' | translate }}</div>
        </a>
      </div>
    </div>
  </div>
</div>
