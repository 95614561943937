<div class="banner justify-content-center" role="banner">
  <h1 class="text-white">{{ 'games.page.trivia.title' | translate }}</h1>
</div>

<fgb-game-nav></fgb-game-nav>

<div class="container">
  <ng-container *ngIf="quizzes$ | async as quizzes">
    <div *ngIf="!quizzes.length">
      <div class="no-items w-100 no-results-placeholder-div">
        <div class="no-results-placeholder">{{ 'trivia.placeholder.description' | translate }}</div>
      </div>
    </div>
    <div *ngFor="let quiz of quizzes">
      <fgb-survey-item [survey]="quiz"></fgb-survey-item>
    </div>
  </ng-container>
</div>
