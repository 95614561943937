<div class="container d-flex align-items-center">
  <div class="card mx-auto mt-lg-0 mt-5 align-self-start align-self-lg-center">
    <div class="card-body text-center py-3">
      <div class="card-heading">
        <h6 class="text-danger mb-0 font-size-14">{{ 'portal.product.failure.page.title' | translate }}</h6>
      </div>
      <div class="mt-4 sub-heading-modal">
        <div class="text-center">{{ 'portal.product.failure.page.card.message' | translate }}</div>
      </div>
      <div class="btn-container mt-2 d-block">
        <button [routerLink]="['/account']" type="button" class="btn btn-danger text-white mx-2 mt-4 fw-bold">
          {{ 'portal.product.failure.page.close.button' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
