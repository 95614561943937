import { Component, Input, OnInit } from '@angular/core';
import { HideMobileNav } from '@fgb/portal-component-library/src/lib/directives';

@Component({
  selector: 'fgb-lotto-detail-page',
  templateUrl: './lotto-detail-page.component.html',
  styleUrls: ['./lotto-detail-page.component.scss'],
  hostDirectives: [
    HideMobileNav
  ]
})
export class LottoDetailPageComponent implements OnInit {
  @Input() id: number = 0;

  constructor() {}

  ngOnInit() {
  }
}
