<div class="banner justify-content-center d-none d-lg-flex" role="banner">
  <h1 class="text-white">{{ name() }}</h1>
</div>

<div class="container" *ifScreenSize="screenType.Mobile">
  <div class="mt-4">
    <fgb-barcode [showCashBackToggle]="true"></fgb-barcode>
  </div>
</div>

<fgb-account-nav></fgb-account-nav>

<div class="container mt-lg-4">
  <div class="row">
    <div class="col-12 col-lg-6 order-lg-1 order-2">
      <h5 class="my-3 mt-lg-0">{{ 'account.details.title' | translate }}</h5>
      <fgb-account-details></fgb-account-details>

      <h5 class="mb-3 mt-4">{{ 'login.details.title' | translate }}</h5>
      <div class="item-container">
        <fgb-edit-login-details />
      </div>

      <div class="item-container d-lg-none d-flex">
        <ng-container *ngTemplateOutlet="cardTemplate"></ng-container>
      </div>
    </div>
    <div class="col-12 col-lg-6 order-lg-2 order-1">
      <div class="item-container">
        <h5 class="mb-3 mt-lg-0">{{ 'subscriptions.list.membership.title' | translate }}</h5>
        <fgb-subscriptions-list></fgb-subscriptions-list>

        <div class="rounded border sub-opt-out" [innerHTML]="'account.opt.out.language' | translate | markdownTranslate"></div>
      </div>

      <div class="item-container d-none d-lg-flex">
        <ng-container *ngTemplateOutlet="cardTemplate"></ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #cardTemplate>
  <fgbcl-card-token-list
    [provider]="provider"
    [redirectUrl]="'/cardtoken/result'"
    [failureUrl]="'/cardtoken/failure'"
    [regex]="regex"
  ></fgbcl-card-token-list>
</ng-template>
