<div class="rounded home-member-card">
  <div class="d-flex">
    <div class="member-details d-flex flex-column" *ngIf="memberDetails$ | async as member">
      <label class="mt-0 mb-2 text-white font-size-14">{{ member?.FirstName + ' ' + member?.Surname }}</label>
      <span class="font-size-10 text-white" *ngIf="memberCard$ | async as memberCard">
        {{ memberCard?.ProductCode | accountMembership | translate }}
        &nbsp;-&nbsp;
        {{ memberCard?.MemberId }}
      </span>
    </div>
    <div class="img-container">
      <img src="assets/images/logo-v2.png" draggable="false" />
    </div>
  </div>
  <ng-container *notInProductCode="[productCode.Premium]">
    <div class="action-buttons">
      <a class="btn text-primary rounded bg-white fw-bold" [routerLink]="'/account'">{{
        'home.page.member.card.upgrade' | translate
      }}</a>
    </div>
  </ng-container>
</div>
