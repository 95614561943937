<h2 class="accessibility-only">Code Redemption</h2>
<div class="card border">
  <h5 class="card-header fw-bold bg-white border-0 font-size-18 text-center me-lg-4 me-0 mb-3 mb-lg-0">
    {{ 'voucher.title' | translate }}
  </h5>
  <div class="d-flex flex-column align-items-center input-container">
    <div class="input-group">
      <input
        type="text"
        [(ngModel)]="voucherCode"
        [placeholder]="'voucher.placeholder' | translate"
        class="form-control text-center border-0 bg-disabled code-input"
        (keyup.enter)="redeemVoucher()"
      />
      <button class="btn btn-primary bg-black text-white border-0 fw-bold" (click)="redeemVoucher()" [disabled]="disableButton">
        <i class="material-icons">chevron_right</i>
      </button>
    </div>

    <div class="result-box">
      <div class="result mt-0">
        <fgbcl-errors></fgbcl-errors>
        <div class="font-italic" *ngIf="redemptionResult">
          {{ redemptionResult }}
        </div>
      </div>
    </div>
  </div>
</div>
