import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'cardTypeImage' })
export class CardTypeImagePipe implements PipeTransform {
  constructor() { }

  transform(value: any) {
    const cardLogo = this._getCardLogoByType(value);
    return `assets/images/payment-icons/${cardLogo}.svg`;
  }

  private _getCardLogoByType(type: number): string {
    switch(type){
      case 1:
        return '_Mastercard';
      case 2:
        return '_AMEX';
      case 3:
        return '_Visa-modified';
      case 4:
        return '_Discover';
      case 5:
        return '_JCB';
      case 7:
        return '_DinnersClub';
      case 8:
        return '_UnionPay';
      default:
        return '';
    }
  }
}
