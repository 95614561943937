import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Cart, CartQuery, CartService } from '@fgb/core';

@Component({
  selector: 'fgb-cart-item-subscriptions',
  templateUrl: './cart-item-subscriptions.component.html',
  styleUrls: ['./cart-item-subscriptions.component.scss'],
})
export class CartItemSubscriptionsComponent implements OnInit {
  items$: Observable<Cart[]>;
  currentLcid: string | null = '';
  currencyCode: string;

  constructor(private cartQuery: CartQuery, private cartService: CartService) {
    this.currentLcid = localStorage.getItem('lcid');
  }

  ngOnInit() {
    this.items$ = this.cartQuery.selectAllSubscriptionCartData();
    this.updateCurrency();
  }

  remove(cartItem: Cart) {
    this.cartService.remove(cartItem.id);
  }

  updateCurrency() {
    if (this.currentLcid == '2057') {
      this.currencyCode = 'GBP';
    } else {
      this.currencyCode = 'EUR';
    }
  }
}
