import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, Optional, Self, WritableSignal, signal } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormsModule, NgControl } from '@angular/forms';

@Component({
  standalone: true,
  selector: 'fgb-input-password',
  templateUrl: './input-password.component.html',
  styleUrls: ['./input-password.component.scss'],
  imports: [ FormsModule, CommonModule ]
})
export class InputPasswordComponent implements ControlValueAccessor, OnInit {

  @Input() placeholder: string = 'Insert password here';
  @Input() label: string = 'Password';
  control: AbstractControl | any;
  onChangeCallback = (_: any) => {};
  onTouchedCallback = (_: any) => {};
  innerValue: WritableSignal<string> = signal('');
  inputType: WritableSignal<'password' | 'text'> = signal('password');

  constructor(@Optional() @Self() private ngControl: NgControl) { 
    if( this.ngControl ) this.ngControl.valueAccessor = this;
  }

  ngOnInit(): void {
    if( this.ngControl ){
      this.control = this.ngControl.control;
      this.control.updateValueAndValidity();
    } 
  }

  writeValue(value: string): void {
    this.innerValue.set(value);
    this.onChangeCallback(this.innerValue());
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  toggleVisibility(): void{
    this.inputType.update(value => value === 'password' ? 'text' : 'password');
  }

}