import { Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { AccountActivationService, AccountActivationStatus } from './account-activation.service';
import { PASSWORD_PATTERN } from 'src/app/shared/utilities/globals';
import { Router } from '@angular/router';
import { AuthService, LoggingService } from '@fgb/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'fgb-activate-account-page',
  templateUrl: './activate-account-page.component.html',
  styleUrl: './activate-account-page.component.scss'
})
export class ActivateAccountPageComponent implements OnInit{
  @Input() key: string;
  @Input() accountStatus = AccountActivationStatus.Active
  activateForm: FormGroup;
  isPasswordVisible = false;
  isConfirmPasswordVisible = false;
  private _destroyRef = inject(DestroyRef);

  constructor(private accountActivationService: AccountActivationService, 
              private loggingService: LoggingService,
              private authService: AuthService,
              private fb: FormBuilder, 
              private router: Router){
    
  }

  ngOnInit() {

    if( this.authService.isAuthenticated() ){
      this.authService.logout().pipe(takeUntilDestroyed(this._destroyRef)).subscribe();
    }
    this._initForm();
  }

  private _initForm(){
    this.activateForm = this.fb.group({
      Password: ['', [ Validators.required, Validators.pattern(PASSWORD_PATTERN) ]],
      ConfirmPassword: ['', [ Validators.required ]]
    },{ validators: [ validateSamePassword ], updateOn: 'submit' });
  }

  submit(): void{
    this.activateForm.markAllAsTouched();
    
    if(this.activateForm.valid){
      const password = this.activateForm.get('Password')?.value;
      this.accountActivationService.activateAccount(this.key, password, this.accountStatus).subscribe({
        next: () => {
          this.loggingService.info('Account activated successfully. Redirecting to login page...');
          this.router.navigate(['/login']);
        },
        error: (err) => {
          this.loggingService.error('Error activating account: ', err);
        }
      });
    }
  }

}

function validateSamePassword(control: AbstractControl): ValidationErrors | null {
  const password = control.get('Password');
  const confirmPassword = control.get('ConfirmPassword');
  return password && confirmPassword && password.value === confirmPassword.value ? null : { 'passwordMismatch': true };
}
