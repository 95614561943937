<!--membership options-->
<div *ngIf="membershipPortalProducts?.length && !foundMembership; else elseRenewals">
  <div class="row">
    <ng-container *ngFor="let membProduct of membershipPortalProducts">
      <div class="col-12 subscription-item">
        <fgb-subscriptions-list-item [portalProduct]="membProduct"></fgb-subscriptions-list-item>
      </div>
    </ng-container>
  </div>
</div>
<!--renewals of memberships-->
<ng-template #elseRenewals>
  <div *ngIf="foundMembership">
    <div class="row">
      <ng-container *ngFor="let product of purchasedProducts">
        <div class="col-12 mb-3" *ngIf="product.ProductType == 1">
          <fgb-subscriptions-list-item-renewals [subscribedProduct]="product"></fgb-subscriptions-list-item-renewals>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
<!--subscription options-->
<!-- <div *ngIf="subscriptionPortalProducts.length || foundSubscription">
  <h3 class="mt-4 font-size-22">Digital Products</h3>
  <div class="row">
    active portal products which are not purchased
    <ng-container *ngIf="notPurchasedActiveSubs.length > 0">
      <ng-container *ngFor="let subProduct of notPurchasedActiveSubs">
        <div class="col-12">
          <fgb-subscriptions-list-item [portalProduct]="subProduct"></fgb-subscriptions-list-item>
        </div>
      </ng-container>
    </ng-container>
    portal products which have been purchased
    <ng-container *ngIf="purchasedProducts.length > 0">
      <ng-container *ngFor="let product of purchasedProducts">
        <div class="col-12" *ngIf="product.ProductType == 3">
          <fgb-subscriptions-list-item-subscribed [subscribedProduct]="product"></fgb-subscriptions-list-item-subscribed>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div> -->

<!--membership and subscription cart modal-->
<!-- <div class="footer-sticky">
  <div class="footer-card my-3" *ngIf="items$ | async as items">
    <div class="card-body px-0" *ngIf="items.length">
      <div class="w-100 p-3 card shadow">
        <div class="d-flex justify-content-between">
          <h3 class="text-primary font-size-16 ps-2">your basket</h3>
          <h3 class="text-primary font-size-14 cancel-button-subs pe-lg-3 pe-2 cursor-pointer" (click)="clearSubsBasket()">
            Cancel
          </h3>
        </div>
        <div class="container mt-3">
          <div class="row">
            <div *ngFor="let item of items" class="col-lg-2 col-4">
              <div
                class="sub-item rounded text-center mb-4"
                [ngClass]="{
                  'bg-primary': item.productData.ProductType == 1,
                  'bg-secondary': item.productData.ProductType == 3
                }"
              >
                <img class="portal-product-img my-2" src="{{ item.productData.PrimaryImage | contentImage : '6' }}" />
              </div>
              <div
                class="rounded-circle bg-white text-danger remove-product text-center shadow fw-bold border-0 cursor-pointer"
                (click)="remove(item)"
              >
                x
              </div>
            </div>
          </div>
          <hr />
          <div class="row mb-3">
            <div class="col-lg-9 col-6 text-lg-end font-size-14 py-2">
              <span class="fw-bold">Total Amount: </span
              ><span *ngIf="totalCost$ | async as totalCost" class="text-success">
                {{ totalCost / 100 | FortressCurrencyPipe }}</span
              >
            </div>
            <div class="col-lg-3 col-6 text-end">
              <a [routerLink]="['/subscriptions/checkout']" routerLinkActive="router-link-active" class="rounded">
                <button class="btn btn-success fw-bold w-100 checkout-btn font-size-14">Checkout</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<!--No new products or active products-->
<ng-container *ngIf="allProducts == 0">
  <div>
    <img class="no-subs-image d-flex mx-auto" src="assets/images/placeholders/no-subscriptions.svg" />
    <h5 class="text-primary text-center mt-3 mb-0 fw-bold text-uppercase">SUBSCRIPTIONS</h5>
    <p class="text-info text-center font-size-14">Currently Unavailable</p>
  </div>
</ng-container>
