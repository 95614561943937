import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CardService, AuthService, ErrorStateService } from '@fgb/core';
import { Subject, firstValueFrom } from 'rxjs';

@Component({
  selector: 'fgb-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.scss'],
})
export class UserLoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  returnUrl: string;
  submitted = false;
  loading = false;
  private _destroyed$ = new Subject<void>();

  constructor(
    public cardService: CardService,
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    public errorService: ErrorStateService,
    private router: Router
  ) {}

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });

    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
  }

  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;

    firstValueFrom(this.authService.login(this.f.username.value, this.f.password.value))
      .then(() => this.router.navigate([this.returnUrl]))
      .catch(() => {
        this.loading = false;
        this.errorService.clearErrors();
        this.errorService.addError('Invalid Email Address and Password.');
      });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
