import { Injectable } from '@angular/core';
import { ApiService, config } from '@fgb/core';
import { Observable } from 'rxjs';

export class UserLoginDetails{
  EmailAddress: string;
  Username: string;
  Password: string;
  AccountNumber: string;
  ClubId: number;
  Lcid: number;

  constructor(o?: any){
    o = o || {};
    this.EmailAddress = o.EmailAddress1 || '';
    this.Username = o.EmailAddress1 || '';
    this.Password = o.Password || '';
    this.AccountNumber = o.AccountNumber || '';
    this.ClubId = o.ClubId || 0;
    this.Lcid = o.Lcid || config.defaultLcid;
  }
}

interface UserEditDetailsBody{
  OldDetails: UserLoginDetails;
  NewDetails: UserLoginDetails;
}

@Injectable({
  providedIn: 'root'
})
export class UserDetailsService {

  constructor(private api: ApiService) { }

  updateLoginDetails(payload: UserEditDetailsBody): Observable<any>{
    return this.api.post('members/changepassword?providerName=1&clubId=1', payload);
  }
}
