<div *ngIf="member$ | async as member">
  <form [formGroup]="detailsForm">
    @if (anyEditMode()) {

    <div class="form-floating position-relative mb-3">
      <input class="form-control" type="text" formControlName="firstName" />
      <label class="text-info">{{ 'edit.account.details.firstname.label' | translate }}</label>

      <div
        class="error-message text-danger"
        *ngIf="detailsForm.get('firstName').touched && detailsForm.get('firstName').hasError('required')"
      >
        {{ 'edit.account.details.firstname.required' | translate }}
      </div>
      <div
        class="error-message text-danger"
        *ngIf="detailsForm.get('firstName').touched && detailsForm.get('firstName').hasError('empty')"
      >
        {{ 'edit.account.details.firstname.empty' | translate }}
      </div>

      <div
        class="error-message text-danger"
        *ngIf="detailsForm.get('firstName').touched && detailsForm.get('firstName').hasError('pattern')"
      >
        {{ 'edit.account.details.firstname.pattern' | translate }}
      </div>
    </div>
    <div class="form-floating position-relative mb-3">
      <input class="form-control" type="text" formControlName="surname" />
      <label class="text-info">{{ 'edit.account.details.lastname.label' | translate }}</label>
      <div
        class="error-message text-danger"
        *ngIf="detailsForm.get('surname').touched && detailsForm.get('surname').hasError('required')"
      >
        {{ 'edit.account.details.surname.required' | translate }}
      </div>
      <div
        class="error-message text-danger"
        *ngIf="detailsForm.get('surname').touched && detailsForm.get('surname').hasError('empty')"
      >
        {{ 'edit.account.details.surname.empty' | translate }}
      </div>

      <div
        class="error-message text-danger"
        *ngIf="detailsForm.get('surname').touched && detailsForm.get('surname').hasError('pattern')"
      >
        {{ 'edit.account.details.surname.pattern' | translate }}
      </div>
    </div>

    }@else {
    <div class="form-field">
      <label class="mb-0">{{ 'edit.account.details.firstname.label' | translate }}</label>
      <div class="d-flex bg-muted p-2 m-0 mt-1 rounded text-field">
        <span class="ms-1 w-75">
          <span>{{ member.FirstName }}</span>
        </span>
      </div>
    </div>

    <div class="form-field">
      <label class="mb-0">{{ 'edit.account.details.lastname.label' | translate }}</label>
      <div class="d-flex bg-muted p-2 m-0 mt-1 rounded text-field">
        <span class="ms-1 w-75">
          <span>{{ member.Surname }}</span>
        </span>
      </div>
    </div>

    }
    <ng-container *ngIf="isLoading">
      <fgbcl-loading></fgbcl-loading>
    </ng-container>

    <ng-container *ngIf="!isLoading">
      <div class="action-buttons justify-content-end d-flex">
        <button
          type="button"
          class="edit-button fw-bold d-block mx-auto border-0 rounded text-white bg-primary"
          *ngIf="notInAnyEditMode()"
          (click)="edit()"
        >
          {{ 'edit.account.details.edit.button' | translate }}
        </button>
        <button
          #autoEditConfirm
          type="button"
          class="save-button fw-bold d-block mx-auto border-0 rounded text-white bg-success"
          *ngIf="autoEditMode"
          [ngClass]="disableIfFormIncomplete()"
          (click)="save()"
        >
          {{ 'edit.account.details.save.button' | translate }}
        </button>
        <div class="d-flex justify-content-between">
          <button type="button" class="btn text-danger cancel-button fw-bold" *ngIf="isInEditMode" (click)="stopEditing()">
            {{ 'edit.account.details.cancel.button' | translate }}
          </button>
          <button
            type="button"
            class="save-button fw-bold d-inline-block border-0 rounded text-white bg-success"
            *ngIf="isInEditMode"
            (click)="save()"
          >
            {{ 'edit.account.details.save.button' | translate }}
          </button>
        </div>
      </div>
    </ng-container>
  </form>
</div>
