<ng-container *ngIf="worksheets$ | withLoading | async as worksheets">
  <ng-container *ngIf="worksheets.data">
    <div *ngFor="let worksheetGroup of worksheets.data">
      <ng-container *ngIf="worksheetGroup.Items?.length > 0; else noItems">
        <div [class.row]="isList">
          <div *ngFor="let item of worksheetGroup.Items" [class.col-lg-4]="isList" [class.col-md-6]="isList">
            <div>
              <fgb-worksheet-item [worksheetItem]="item" [worksheetId]="worksheetGroup.WorksheetId"></fgb-worksheet-item>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <ng-template #noItems>
    <div class="container">
      <div class="no-items w-100 no-results-placeholder-div">
        <div class="no-results-placeholder">{{ 'worksheet.placeholder.description' | translate }}</div>
      </div>
    </div>
  </ng-template>

  <!-- Error -->
  <ng-container *ngIf="worksheets.error">
    <p>Error {{ worksheets.error }}</p>
  </ng-container>

  <!-- Loading -->
  <ng-container *ngIf="worksheets.isLoading">
    <div class="w-100">
      <fgbcl-loading></fgbcl-loading>
    </div>
  </ng-container>
</ng-container>
