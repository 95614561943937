import { Component, OnInit, Input } from '@angular/core';
import { FantasyHistory, FantasyGame, GameStatus } from '@fgb/core';
import { Moment } from 'moment';
import * as _moment from 'moment';
const moment = _moment;

@Component({
  selector: 'fgb-fantasy-history-list-item',
  templateUrl: './fantasy-history-list-item.component.html',
  styleUrls: ['./fantasy-history-list-item.component.scss'],
})
export class FantasyHistoryListItemComponent implements OnInit {
  @Input() game: FantasyGame;
  @Input() fantasyHistoryItem: FantasyHistory;

  gameDate: Moment;
  gameTime: string;
  isPending: boolean = false;

  constructor() {}

  ngOnInit() {
    this.gameDate = moment.utc(this.fantasyHistoryItem.GameDate);
    this.gameTime = moment.utc(this.fantasyHistoryItem.GameDate).format('HH:mm');

    this.isPending = this.fantasyHistoryItem.Status === GameStatus.Ready;
  }
}
